import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRowSelect, SelectTypes } from "@table-library/react-table-library/select";
import { Link } from "react-router-dom";
import moment from "moment";
import cs from "classnames";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";
import ItemForm from "../../components/Form/ItemForm";

import useEditForm from "hooks/useEditForm";
import { translateType } from "pages/Root/helpers";
import Move from "pages/Root/components/PopForm/Move";
import useRoles from "hooks/useRoles";

export default function Item() {
	const { items, units, placements, places } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (items === null) return;

		const newData = Object.values(items).map(item => {
			const newestPlacement = Object.values(placements || {})
				.filter(placement => placement.items.find(url => url === item["@id"]))
				.sort((a, b) =>
					moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
						? 1
						: -1
				)[0];
			return {
				...item,
				belongsTo: units?.[item.belongsTo],
				located: places?.[newestPlacement?.located],
			};
		});
		setData({
			nodes: newData
				.sort((a, b) => a.type.localeCompare(b.type))
				.sort((a, b) => +b.state - +a.state),
		});
	}, [items, units, placements, places]);

	const select = useRowSelect(
		data,
		{},
		{
			rowSelect: SelectTypes.MultiSelect,
		}
	);
	const selected = useMemo(() => {
		if (select.state.id) return [select.state.id];
		return [...select.state.ids];
	}, [select]);

	const columns = [
		{
			label: "Typ",
			renderCell: item => translateType(item.type),
			sort: { sortKey: "TYPE" },
		},
		{
			label: "Sériové číslo",
			renderCell: item => item.serialNumber,
			sort: { sortKey: "SERIALNUMBER" },
		},
		{
			label: "Označení",
			renderCell: item => item.label,
			sort: { sortKey: "LABEL" },
		},
		{
			label: "Výrobce",
			renderCell: item => item.manufacturer,
			sort: { sortKey: "MANUFACTURER" },
		},
		{
			label: "Stav",
			renderCell: item => (item.state ? "✔" : "✖"),
			sort: { sortKey: "STATE" },
		},
		{
			label: "Jednotka",
			search: item => item.belongsTo?.name || item.belongsTo,
			renderCell: item => (
				<Link to={`/jednotky?hledat=${item.belongsTo?.name || ""}`}>
					{item.belongsTo?.name || item.belongsTo}
				</Link>
			),
			sort: { sortKey: "BELONGSTO" },
		},
		{
			label: "Místo",
			search: item => item.located?.name || "",
			renderCell: item => (
				<Link to={`/mista?hledat=${item.located?.name || ""}`}>{item.located?.name || ""}</Link>
			),
			sort: { sortKey: "PLACE" },
		},
	];

	const sortFns = {
		TYPE: array => array.sort((a, b) => translateType(a.type).localeCompare(translateType(b.type))),
		SERIALNUMBER: array => array.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber)),
		LABEL: array => array.sort((a, b) => a.label.localeCompare(b.label)),
		MANUFACTURER: array => array.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer)),
		STATE: array => array.sort((a, b) => +b.state - +a.state),
		UNIT: array => array.sort((a, b) => a.unit.name.localeCompare(b.unit.name)),
		PLACE: array => array.sort((a, b) => a.located?.name.localeCompare(b.located?.name)),
	};

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const [moveToggle, setMoveToggle] = useState(false);

	const { isManipulator, isAdmin } = useRoles();

	if (items === null) return <Loading />;

	return (
		<React.Fragment>
			{moveToggle && (
				<Move selected={selected.map(item => `/api/items/${item}`)} setMoveToggle={setMoveToggle} />
			)}
			<main>
				<h2>
					<picture>
						<source srcSet="/images/icons/item_color.svg" />
						<img width="36px" src="/images/icons/item_color.svg" alt="Ikona předmětu" />
					</picture>
					Předměty
					<menu>
						{isManipulator ? (
							<button
								className={cs("action_button", { inactive: !selected.length })}
								onClick={() => {
									if (selected.length) setMoveToggle(prevState => !prevState);
								}}>
								Přemístit
							</button>
						) : null}
						{isAdmin ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => setToggle(prevState => !prevState)}>
								{toggle ? "Zavřít formulář" : "Přidat předmět"}
							</button>
						) : null}
					</menu>
				</h2>
				{toggle && <ItemForm initialValues={items[editing]} id={editing} key={formKey} />}
				<CustomTable
					title="Seznam všech předmětů"
					layout="minmax(7rem, 1fr) minmax(6rem, 1fr) minmax(12rem, 1fr) minmax(7rem, 1fr) minmax(5rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr)"
					openUrl={item => `/predmety/${item.id}`}
					data={data}
					columns={columns}
					sortFns={sortFns}
					select={select}
					actions={["edit", "delete", "open"]}
					setEditing={setEditing}
				/>
			</main>
		</React.Fragment>
	);
}
