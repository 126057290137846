import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

CustomLink.propTypes = {
	to: PropTypes.string.isRequired, // URL to navigate to
	children: PropTypes.node.isRequired, // Content of the link
	id: PropTypes.string, // Optional ID for the link
};

/**
 * Component for rendering custom links based on the current route.
 * If the current route matches the 'to' prop, it renders a plain text span.
 * Otherwise, it renders a React Router Link component.
 * @param {Object} props - Component props
 * @param {string} props.to - URL to navigate to
 * @param {ReactNode} props.children - Content of the link
 * @param {string} [props.id] - Optional ID for the link
 * @returns {JSX.Element} - Rendered CustomLink component
 */
export default function CustomLink({ to, children, id }) {
	const { pathname } = useLocation();

	// Render plain text span if the current route matches the 'to' prop
	if (pathname === to) return <span id={id}>{children}</span>;

	// Render React Router Link component for navigation
	return (
		<Link to={to} id={id}>
			{children}
		</Link>
	);
}
