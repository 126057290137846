import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import RevisionTable from "pages/Root/components/Table/RevisionTable";
import NotFilledBottleTable from "pages/Root/components/Table/NotFilledBottleTable";
import UsersUsagesBottle from "pages/Root/components/Table/UsersUsagesBottle";

export default function Index() {
	const { revisions } = useSelector(state => state.data);

	return (
		<main>
			<h2>
				<picture>
					<source srcSet="/logo192.png" />
					<img width="34px" src="/logo192.png" alt="Ikona aplikace" />
				</picture>
				Domovská stránka
			</h2>
			<RevisionTable
				title="Nejbližší revize"
				about="Seznam prošlých a blížících se revizí"
				displayActions={false}
				revisions={
					revisions &&
					Object.values(revisions).filter(revision =>
						moment(revision.next, "YYYY-MM-DDTHH:mm:ssZ").isAfter(moment().subtract(1, "months"))
					)
				}
				defaultPageSize={10}
			/>
			<NotFilledBottleTable
				title="Blížící se nepoužité lahve (6 měsíců)"
				about="Lahve, u kterých nebylo zaznamenáno plnění na více jak 6 měsíců."
			/>
			<UsersUsagesBottle
				title="Uživatelé, kteří nepoužili láhev (3 měsíce)"
				about="Uživatelé, kteří nemají záznam použití nějaké lahve za uplynulé 3 měsíce."
			/>
		</main>
	);
}
