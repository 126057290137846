import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cs from "classnames";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";

import PlaceForm from "../../components/Form/PlaceForm";

import useEditForm from "hooks/useEditForm";
import useRoles from "hooks/useRoles";

export default function Place() {
	const { places } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (places === null) return;

		setData({ nodes: Object.values(places).sort((a, b) => a.name.localeCompare(b.name)) });
	}, [places]);

	const columns = [
		{
			label: "Název",
			renderCell: place => place.name,
			sort: { sortKey: "NAME" },
		},
		{
			label: "Popis",
			renderCell: place => place.description,
			sort: { sortKey: "DESCRIPTION" },
		},
	];

	const sortFns = {
		NAME: array => array.sort((a, b) => a.name.localeCompare(b.name)),
		DESCRIPTION: array => array.sort((a, b) => a.description.localeCompare(b.description)),
	};

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isAdmin } = useRoles();

	if (places === null) return <Loading />;

	return (
		<main>
			<h2>
				<picture>
					<source srcSet="/images/icons/place_color.svg" />
					<img width="36px" src="/images/icons/place_color.svg" alt="Ikona místa" />
				</picture>
				Místa
				<menu>
					{isAdmin ? (
						<button
							className={cs("action_button", { active: toggle })}
							onClick={() => setToggle(prevState => !prevState)}>
							{toggle ? "Zavřít formulář" : "Přidat místo"}
						</button>
					) : null}
				</menu>
			</h2>
			{toggle && <PlaceForm initialValues={places[editing]} id={editing} key={formKey} />}
			<CustomTable
				title="Seznam všech míst"
				layout="minmax(10rem, 1fr) minmax(10rem, 1fr)"
				data={data}
				columns={columns}
				sortFns={sortFns}
				actions={["edit", "delete"]}
				setEditing={setEditing}
			/>
		</main>
	);
}
