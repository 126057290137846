import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Loading from "components/Loading";

import UsageTable from "pages/Root/components/Table/UsageTable";
import useEditForm from "hooks/useEditForm";
import UsageForm from "pages/Root/components/Form/UsageForm";
import useRoles from "hooks/useRoles";

UsageDetail.propTypes = {
	usages: PropTypes.array,
	about: PropTypes.string,
	item: PropTypes.object,
	user: PropTypes.object,
};

export default function UsageDetail({ usages, about, user, item }) {
	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isAdmin } = useRoles();

	if (!usages) return <Loading />;

	return (
		<React.Fragment>
			<section id="pouziti" className="noBreak">
				<h3>
					Použití
					<menu>
						{isAdmin ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => setToggle(prevState => !prevState)}>
								{toggle ? "Zavřít formulář" : "Přidat použití"}
							</button>
						) : null}
					</menu>
				</h3>
				{about ? <p>{about}</p> : null}
				{toggle && (
					<UsageForm
						initialValues={usages.find(usage => usage["@id"] === editing)}
						id={editing}
						key={formKey}
						item={item?.["@id"]}
						user={user}
					/>
				)}
			</section>
			<UsageTable
				title="Seznam provedených použití předmětu"
				usages={usages}
				defaultPageSize={10}
				setEditing={setEditing}
			/>
		</React.Fragment>
	);
}
