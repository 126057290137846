import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import cs from "classnames";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";

UsersUsagesBottle.propTypes = {
	title: PropTypes.string,
	about: PropTypes.string,
	defaultPageSize: PropTypes.number,
};

export default function UsersUsagesBottle({ title, about, defaultPageSize = 30 }) {
	const { users, usages, bottles, items } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (usages === null || usages === null) return;

		const newData = Object.values(users || {}).map(user => {
			const userUsages = Object.values(usages || {})
				.filter(usage => usage.usedBy === user["@id"])
				.filter(
					usage =>
						usage.items.filter(item =>
							Object.values(bottles || {}).find(bottle => bottle.item === item)
						).length
				)
				.sort((a, b) =>
					moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
						? 1
						: -1
				);

			return {
				user,
				usage: { ...userUsages?.[0], items: userUsages?.[0]?.items?.map(item => items[item]) },
			};
		});

		setData({
			nodes: newData.sort((a, b) => {
				if (a.usage?.date === undefined) return 1;
				if (b.usage?.date === undefined) return -1;

				return moment(a.usage?.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(
					moment(b.usage?.date, "YYYY-MM-DDTHH:mm:ssZ")
				)
					? -1
					: 1;
			}),
		});
	}, [users, usages, bottles, items]);

	const columns = [
		{
			label: "Uživatel",
			search: row => row.user.username,
			renderCell: row => <Link to={`/uzivatele/${row.user.id || ""}`}>{row.user.username}</Link>,
			sort: { sortKey: "USER" },
		},
		{
			label: "Poslední použití lahve",
			search: row => (row.usage?.date ? moment(row.usage.date).format("D. M. YYYY") : "-"),
			renderCell: row =>
				row.usage?.date ? (
					<span
						className={cs({
							red: moment(row.usage?.date).isBefore(moment().subtract(3, "months")),
						})}>
						{moment(row.usage?.date).format("D. M. YYYY H:mm")}
					</span>
				) : (
					"-"
				),
			sort: { sortKey: "DATE" },
		},
		{
			label: "Předměty",
			search: row => row.usage.items.map(item => item?.label || "-").join(", "),
			renderCell: row =>
				row.usage?.items?.map((item, key) => (
					<React.Fragment key={key}>
						{key === 0 ? "" : ", "}
						<Link to={`/predmety/${item?.id || ""}`}>{item?.label || "-"}</Link>
					</React.Fragment>
				)),
			sort: { sortKey: "ITEMS" },
		},
	];

	const sortFns = {
		USER: array => array.sort((a, b) => a.user.username.localeCompare(b.user.username)),
		DATE: array =>
			array.sort((a, b) =>
				moment(a.usage.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(
					moment(b.usage.date, "YYYY-MM-DDTHH:mm:ssZ")
				)
					? -1
					: 1
			),
		ITEMS: array =>
			array.sort((a, b) =>
				a.usage.items
					?.map(item => item.label)
					.join(", ")
					.localeCompare(b.usage.items?.map(item => item.label).join(", "))
			),
	};

	if (usages === null) return <Loading />;

	return (
		<CustomTable
			title={title}
			about={about}
			layout="minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(35rem, 1fr)"
			data={data}
			columns={columns}
			sortFns={sortFns}
			defaultPageSize={defaultPageSize}
		/>
	);
}
