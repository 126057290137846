import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import cs from "classnames";

import { translateRole } from "pages/Root/helpers";
import { createObject, updateObject } from "../../../../../redux/dataSlice";
import { filterProperties } from "pages/Root/helpers";
import useNotifications from "hooks/useNotifications";
import CustomSelect from "components/CustomSelect";

UserForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	subtitle: PropTypes.bool,
};

export default function UserForm({ initialValues, id = undefined, subtitle = false }) {
	const defaultValues = {
		username: "",
		plainPassword: "",
		forename: "",
		surnames: "",
		unit: "",
		active: true,
		roles: [],
	};
	const mergedDefaults = { ...defaultValues, ...initialValues };

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		username: Yup.string().min(2, "Příliš krátké uživatelské jméno").required("Povinné pole"),
		plainPassword: id ? Yup.string().nullable() : Yup.string().required("Povinné pole"),
		forename: Yup.string().nullable(),
		surnames: Yup.string().nullable(),
		unit: Yup.string().nullable(),
		active: Yup.boolean(),
		roles: Yup.array().of(Yup.string()).nullable(),
	});

	const { units } = useSelector(state => state.data);
	const unitOptions = useMemo(() => {
		return Object.entries(units || {})?.map(([id, unit]) => ({
			label: unit.name,
			value: id,
		}));
	}, [units]);

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("users");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	return (
		<section>
			{subtitle ? (
				<h4>
					{id ? "Upravit" : "Vytvořit nového"} uživatele {mergedDefaults.username}
				</h4>
			) : (
				<h3>
					{id ? "Upravit" : "Vytvořit nového"} uživatele {mergedDefaults.username}
				</h3>
			)}
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					dispatch(
						id
							? updateObject({ entity: "users", id, values })
							: createObject({ entity: "users", values })
					);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className="form">
						<label>
							Uživatelské jméno
							<Field type="text" name="username" style={{ width: "10rem" }} />
							<ErrorMessage name="username" component="span" className="error-message" />
						</label>
						<label>
							Heslo
							<Field type="password" name="plainPassword" style={{ width: "10rem" }} />
							<ErrorMessage name="plainPassword" component="span" className="error-message" />
						</label>
						<label>
							Křestní jména
							<Field type="text" name="forename" style={{ width: "10rem" }} />
							<ErrorMessage name="forename" component="span" className="error-message" />
						</label>
						<label>
							Příjmení
							<Field type="text" name="surnames" style={{ width: "9rem" }} />
							<ErrorMessage name="surnames" component="span" className="error-message" />
						</label>
						<label>
							Jednotka
							<Field
								name="unit"
								component={CustomSelect}
								placeholder="Vyberte jednotku..."
								defaultOptions={unitOptions}
								isMulti={false}
							/>
							<ErrorMessage name="unit" component="span" className="error-message" />
						</label>
						<label>
							Aktivní
							<Field type="checkbox" name="active" />
							<ErrorMessage name="active" component="span" className="error-message" />
						</label>
						<label>
							Role
							<Field
								name="roles"
								component={CustomSelect}
								placeholder="Vyberte role..."
								defaultOptions={[
									{ label: translateRole("ROLE_ADMIN"), value: "ROLE_ADMIN" },
									{ label: translateRole("ROLE_TECHNICIAN"), value: "ROLE_TECHNICIAN" },
									{ label: translateRole("ROLE_MANIPULATOR"), value: "ROLE_MANIPULATOR" },
								]}
								isMulti={true}
							/>
							<ErrorMessage name="roles" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Vytvořit"}
						</button>
					</Form>
				)}
			</Formik>
		</section>
	);
}
