import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import Loading from "components/Loading";

import FillingDetail from "../FillingDetail";

CompressorDetail.propTypes = {
	item: PropTypes.object,
};

export default function CompressorDetail({ item }) {
	const { compressors, fillings, revisions } = useSelector(state => state.data);
	const [compressor, setCompressor] = useState(null);

	useEffect(() => {
		if (compressors === null) return;

		if (Object.values(compressors).length) {
			setCompressor(() =>
				Object.values(compressors).find(compressor => compressor.item === `/api/items/${item.id}`)
			);
		}
	}, [compressors]);

	const emplacedRevisions = useMemo(() => {
		return Object.values(revisions || {})
			?.filter(revision => revision.items.find(url => url === item?.["@id"]))
			.sort((a, b) =>
				moment(a.next, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.next, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			);
	}, [item?.revisions, revisions]);

	const emplacedFillings = useMemo(() => {
		return Object.values(fillings || {})
			?.filter(filling => filling.filledBy === compressor?.["@id"])
			.filter(
				filling =>
					emplacedRevisions?.[0] === undefined ||
					moment(filling.start, "YYYY-MM-DDTHH:mm:ssZ").isAfter(
						moment(emplacedRevisions?.[0]?.date, "YYYY-MM-DDTHH:mm:ssZ")
					)
			)
			.sort((a, b) =>
				moment(a.start, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.start, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			);
	}, [compressor, fillings, emplacedRevisions]);

	const totalHours = useMemo(() => {
		let total = 0;

		emplacedFillings?.forEach(filling => {
			if (filling === undefined) return;
			const start = moment(filling.start, "YYYY-MM-DDTHH:mm:ssZ");
			const finish = moment(filling.finish, "YYYY-MM-DDTHH:mm:ssZ");
			const duration = moment.duration(finish.diff(start));
			total += duration.asHours();
		});

		return total.toFixed(2);
	}, [emplacedFillings]);

	if (!compressor || !fillings) return <Loading />;

	return (
		<React.Fragment>
			<section>
				<h3>Informace kompresoru</h3>
				<dl className="description_list">
					<div>
						<dt>Počet motohodin od poslední revize</dt>
						<dd>{totalHours} hodin</dd>
					</div>
				</dl>
			</section>
			<FillingDetail
				item={compressor}
				fillings={
					fillings &&
					Object.values(fillings).filter(filling => filling.filledBy === compressor["@id"])
				}
				about="Zde je seznam všech plnění tímto kompresorem."
			/>
		</React.Fragment>
	);
}
