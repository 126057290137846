import axios from "axios";

/**
 * Makes HTTP requests using Axios library.
 * @param {string} url - The URL to which the request will be made.
 * @param {string} [method="get"] - The HTTP method for the request (default is "get").
 * @param {*} [body=undefined] - The data to be sent as the request body.
 * @returns {Promise} A Promise that resolves to the Axios response object.
 */
export default async function client(url, method = "get", body = undefined) {
	const host = process.env.REACT_APP_HOST + url;

	// Setting up headers
	const headers = {};

	// Retrieve credentials from local storage
	const credentialsRaw = window.localStorage.getItem("credentials");
	const credentials = JSON.parse(credentialsRaw);
	// Add Authorization header if token exists in credentials
	if (credentials && credentials.token) headers.Authorization = `Bearer ${credentials.token}`;

	// Set content type based on method
	if (method === "post" && url !== "/api/login") headers["Content-Type"] = "application/ld+json";
	else if (method === "patch") headers["Content-Type"] = "application/merge-patch+json";

	// Create Axios configuration object with headers
	const config = { headers };

	// Execute request based on method
	switch (method) {
		case "get":
			return axios.get(host, config);
		case "post":
			return axios.post(host, body, config);
		case "patch":
			return axios.patch(host, body, config);
		case "delete":
			return axios.delete(host, config);
		default:
			return; // Return undefined for unsupported methods
	}
}
