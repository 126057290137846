import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRowSelect, SelectTypes } from "@table-library/react-table-library/select";
import cs from "classnames";
import moment from "moment";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";
import ItemForm from "pages/Root/components/Form/ItemForm";
import BottleForm from "../../components/Form/BottleForm";
import Move from "pages/Root/components/PopForm/Move";
import Fill from "pages/Root/components/PopForm/Fill";

import useEditForm from "hooks/useEditForm";
import useRoles from "hooks/useRoles";

export default function Bottle() {
	const { items, bottles, placements, places } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (bottles === null) return;

		const newData = Object.values(bottles).map(bottle => {
			const newestPlacement = Object.values(placements || {})
				.filter(placement => placement.items.find(url => url === bottle.item))
				.sort((a, b) =>
					moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
						? 1
						: -1
				)[0];
			return { ...bottle, item: items?.[bottle.item], located: places?.[newestPlacement?.located] };
		});
		setData({
			nodes: newData.sort((a, b) => {
				if (a.item?.label === undefined) return 1;
				else if (b.item?.label === undefined) return -1;
				else a.item.label.localeCompare(b.item.label);
			}),
		});
	}, [bottles, items, placements, places]);

	const select = useRowSelect(
		data,
		{},
		{
			rowSelect: SelectTypes.MultiSelect,
		}
	);
	const selected = useMemo(() => {
		if (select.state.id) return [select.state.id];
		return [...select.state.ids];
	}, [select]);

	const columns = [
		{
			label: "Předmět (S/N)",
			search: bottle => bottle.item?.label || "-",
			renderCell: bottle => (
				<Link to={`/predmety/${bottle.item?.id || ""}`}>{bottle.item?.label || "-"}</Link>
			),
			sort: { sortKey: "ITEM" },
		},
		{ label: "Kapacita [l]", renderCell: bottle => bottle.capacity, sort: { sortKey: "CAPACITY" } },
		{
			label: "Plnící tlak [bar]",
			renderCell: bottle => bottle.fillingPressure,
			sort: { sortKey: "FILLINGPRESSURE" },
		},
		{
			label: "Plnící médium",
			renderCell: bottle => bottle.fillingMedium,
			sort: { sortKey: "FILLINGMEDIUM" },
		},
		{
			label: "Místo",
			search: compressor => compressor.located?.name || "",
			renderCell: compressor => (
				<Link to={`/mista?hledat=${compressor.located?.name || ""}`}>
					{compressor.located?.name || ""}
				</Link>
			),
			sort: { sortKey: "PLACE" },
		},
	];

	const sortFns = {
		ITEM: array => array.sort((a, b) => a.item.label.localeCompare(b.item.label)),
		CAPACITY: array => array.sort((a, b) => a.capacity - b.capacity),
		FILLINGPRESSURE: array => array.sort((a, b) => a.fillingPressure - b.fillingPressure),
		FILLINGMEDIUM: array => array.sort((a, b) => a.fillingMedium.localeCompare(b.fillingMedium)),
		PLACE: array => array.sort((a, b) => a.located?.name.localeCompare(b.located?.name)),
	};

	const addForm = useEditForm();
	const bottleForm = useEditForm();

	const [fillToggle, setFillToggle] = useState(false);
	const [moveToggle, setMoveToggle] = useState(false);

	const { isTechnician, isManipulator, isAdmin } = useRoles();

	if (bottles === null) return <Loading />;

	return (
		<React.Fragment>
			{moveToggle && (
				<Move
					selected={selected.map(
						id => Object.values(bottles).find(bottle => bottle.id === id).item
					)}
					setMoveToggle={setMoveToggle}
				/>
			)}
			{fillToggle && (
				<Fill
					selected={selected.map(
						id => Object.values(bottles).find(bottle => bottle.id === id)["@id"]
					)}
					setFillToggle={setFillToggle}
				/>
			)}
			<main>
				<h2>
					<picture>
						<source srcSet="/images/icons/bottle_color.svg" />
						<img width="36px" src="/images/icons/bottle_color.svg" alt="Ikona lahve" />
					</picture>
					Láhve
					<menu>
						{isTechnician ? (
							<button
								className={cs("action_button", { inactive: !selected.length })}
								onClick={() => {
									if (selected.length) setFillToggle(prevState => !prevState);
								}}>
								Plnit
							</button>
						) : null}
						{isManipulator ? (
							<button
								className={cs("action_button", { inactive: !selected.length })}
								onClick={() => {
									if (selected.length) setMoveToggle(prevState => !prevState);
								}}>
								Přemístit
							</button>
						) : null}
						{isAdmin ? (
							<button
								className={cs("action_button", { active: addForm.toggle })}
								onClick={() => addForm.setToggle(prevState => !prevState)}>
								{addForm.toggle ? "Zavřít formulář" : "Přidat láhev"}
							</button>
						) : null}
						{bottleForm.toggle && (
							<button
								className={cs("action_button active")}
								onClick={() => bottleForm.setToggle(false)}>
								{"Zavřít úpravu"}
							</button>
						)}
					</menu>
				</h2>
				{addForm.toggle && <ItemForm key={addForm.formKey} initialValues={{ type: "BOTTLE" }} />}
				{bottleForm.editing && (
					<BottleForm
						initialValues={bottles[bottleForm.editing]}
						id={bottleForm.editing}
						key={bottleForm.formKey}
					/>
				)}
				<CustomTable
					title="Seznam všech lahví"
					layout="minmax(16rem, 1fr) minmax(8rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(8rem, 1fr)"
					openUrl={bottle => `/predmety/${bottle.item?.id}`}
					deleteUrl={bottle => bottle.item?.["@id"]}
					data={data}
					columns={columns}
					sortFns={sortFns}
					select={select}
					actions={["edit", "delete", "open"]}
					setEditing={bottleForm.setEditing}
				/>
			</main>
		</React.Fragment>
	);
}
