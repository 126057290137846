import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import cs from "classnames";

import { createObject, updateObject } from "../../../../../redux/dataSlice";
import useNotifications from "hooks/useNotifications";
import CustomSelect from "components/CustomSelect";
import { filterProperties } from "pages/Root/helpers";

PlacementForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	moving: PropTypes.array,
	user: PropTypes.object,
	withHeader: PropTypes.bool,
	setClose: PropTypes.func,
};

export default function PlacementForm({
	initialValues,
	id,
	moving,
	user,
	withHeader = true,
	setClose = () => {},
}) {
	const session = useSelector(state => state.settings.session);

	let defaultValues = {
		movedBy: user?.["@id"] || session["@id"],
		date: "",
		located: "",
		items: [],
	};
	if (moving) defaultValues.items = moving;

	// Convert initial dates to Moment objects
	const defaultDate = initialValues?.date
		? moment(initialValues.date).format("YYYY-MM-DD")
		: moment().format("YYYY-MM-DD");

	const mergedDefaults = {
		...defaultValues,
		...initialValues,
		date: defaultDate,
	};

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		movedBy: Yup.string().required("Povinné pole"),
		date: Yup.date().required("Povinné pole"),
		located: Yup.string().required("Povinné pole"),
		items: Yup.array()
			.of(Yup.string().required("Povinné pole"))
			.min(1, "Musí obsahovat minimálně jeden předmět"),
	});

	const { users, items, places } = useSelector(state => state.data);
	const userOptions = Object.entries(users)?.map(([id, user]) => ({
		label: user.username,
		value: id,
	}));

	const itemOptions = Object.entries(items)?.map(([id, item]) => ({
		label: item?.label || "-",
		value: id,
	}));

	const placeOptions = Object.entries(places)?.map(([id, place]) => ({
		label: place.name,
		value: id,
	}));

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("placements");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	const renderForm = () => {
		return (
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					dispatch(
						id
							? updateObject({ entity: "placements", id, values })
							: createObject({ entity: "placements", values, useCategory: withHeader })
					);
					setClose(false);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className={cs("form", { withoutHeader: !withHeader })}>
						<label>
							Přemístil
							<Field
								name="movedBy"
								defaultOptions={userOptions}
								component={CustomSelect}
								placeholder="Vyberte uživatele..."
								isMulti={false}
							/>
							<ErrorMessage name="movedBy" component="span" className="error-message" />
						</label>
						<label>
							Datum
							<Field type="date" name="date" />
							<ErrorMessage name="date" component="span" className="error-message" />
						</label>
						<label>
							Místo
							<Field
								name="located"
								defaultOptions={placeOptions}
								component={CustomSelect}
								placeholder="Vyberte místo..."
								isMulti={false}
							/>
							<ErrorMessage name="located" component="span" className="error-message" />
						</label>
						<label>
							Předměty
							<Field
								name="items"
								defaultOptions={itemOptions}
								component={CustomSelect}
								placeholder="Vyberte předměty..."
								isMulti={true}
							/>
							<ErrorMessage name="items" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Přemístit"}
						</button>
					</Form>
				)}
			</Formik>
		);
	};

	if (!withHeader) return renderForm();

	return (
		<section>
			<h4>{id ? "Upravit" : "Vytvořit nové"} umístění</h4>
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			{renderForm()}
		</section>
	);
}
