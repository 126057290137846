import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";

UsageTable.propTypes = {
	title: PropTypes.string,
	usages: PropTypes.array,
	defaultPageSize: PropTypes.number,
	setEditing: PropTypes.func,
};

export default function UsageTable({
	title = "Seznam všech použití",
	usages = null,
	defaultPageSize = 30,
	setEditing,
}) {
	const { users, items } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (usages === null) return;

		const newData = usages.map(usage => {
			const newItems = usage.items.map(item => items?.[item]);
			return { ...usage, usedBy: users?.[usage.usedBy], items: newItems };
		});
		setData({
			nodes: newData.sort((a, b) =>
				moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			),
		});
	}, [usages, users, items]);

	const columns = [
		{
			label: "Použil",
			search: usage => usage.usedBy?.username || usage.usedBy,
			renderCell: usage => (
				<Link to={`/uzivatele/${usage.usedBy?.id || ""}`}>
					{usage.usedBy?.username || usage.usedBy}
				</Link>
			),
			sort: { sortKey: "USEDBY" },
		},
		{
			label: "Datum",
			renderCell: usage => usage.date && moment(usage.date).format("D. M. YYYY"),
			sort: { sortKey: "DATE" },
		},
		{
			label: "Předměty",
			search: usage => usage.items.map(item => item?.label || "-").join(", "),
			renderCell: usage =>
				usage.items.map((item, key) => (
					<React.Fragment key={key}>
						{key === 0 ? "" : ", "}
						<Link to={`/predmety/${item?.id || ""}`}>{item?.label || "-"}</Link>
					</React.Fragment>
				)),
			sort: { sortKey: "ITEMS" },
		},
	];

	const sortFns = {
		USEDBY: array => array.sort((a, b) => a.usedBy.username.localeCompare(b.usedBy.username)),
		DATE: array =>
			array.sort((a, b) =>
				moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
					? -1
					: 1
			),
		ITEMS: array =>
			array.sort((a, b) =>
				a.items
					.map(item => item.label)
					.join(", ")
					.localeCompare(b.items.map(item => item.label).join(", "))
			),
	};

	if (usages === null) return <Loading />;

	return (
		<CustomTable
			title={title}
			layout="minmax(7rem, 1fr) minmax(7rem, 1fr) minmax(24rem, 1fr)"
			data={data}
			columns={columns}
			sortFns={sortFns}
			actions={["edit", "delete"]}
			defaultPageSize={defaultPageSize}
			setEditing={setEditing}
		/>
	);
}
