import React from "react";

import useNotifications from "../../../../hooks/useNotifications";

import "./style.sass";

/**
 * Notifications component displays notification messages to the user.
 * It retrieves notification messages using the useNotifications hook.
 * All notifications are displayed in the top middle of the window below each other.
 *
 * @returns {JSX.Element} List of notification messages.
 */
export default function Notifications() {
	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications();

	return (
		<ul id="page_root_component_notifications">
			{notifications.length
				? notifications.map((notification, key) => (
						<li key={key} className={notification.type}>
							{notification.message}
						</li>
					))
				: null}
		</ul>
	);
}
