import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cs from "classnames";

import { translateRole } from "pages/Root/helpers";
import Loading from "components/Loading";
import CustomTable from "components/CustomTable";
import UserForm from "../../components/Form/UserForm";

import useEditForm from "hooks/useEditForm";
import { updateObject } from "../../../../redux/dataSlice";
import useRoles from "hooks/useRoles";

export default function User() {
	const { users, units } = useSelector(state => state.data);
	const dispatch = useDispatch();

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (users === null) return;

		const newData = Object.values(users).map(user => {
			return { ...user, unit: units?.[user.unit] };
		});
		setData({
			nodes: newData
				.sort((a, b) => a.username.localeCompare(b.username))
				.sort((a, b) => +b.active - +a.active),
		});
	}, [users, units]);

	const columns = [
		{
			label: "Uživatelské jméno",
			renderCell: user => user.username,
			sort: { sortKey: "USERNAME" },
		},
		{
			label: "Křestní jména",
			renderCell: user => user.forename,
			sort: { sortKey: "FORENAME" },
		},
		{
			label: "Příjmení",
			renderCell: user => user.surnames,
			sort: { sortKey: "SURNAMES" },
		},
		{
			label: "Jednotka",
			search: user => user.unit?.name || user.unit,
			renderCell: user => (
				<Link to={`/jednotky?hledat=${user.unit?.name || ""}`}>{user.unit?.name || user.unit}</Link>
			),
			sort: { sortKey: "UNIT" },
		},
		{
			label: "Role",
			renderCell: user => user.roles.map(role => translateRole(role)).join(", "),
			sort: { sortKey: "ROLES" },
		},
		{
			label: "Status",
			renderCell: user => (user.active ? "aktivní" : "neaktivní"),
			sort: { sortKey: "STATUS" },
		},
	];

	const lockUser = user => {
		dispatch(
			updateObject({
				entity: "users",
				id: user["@id"],
				values: { active: !user.active },
				useCategory: false,
			})
		);
	};

	const actionDefinitions = {
		lock: {
			label: "",
			renderCell: user => {
				return (
					<span className="link" onClick={() => lockUser(user)}>
						{user.active ? (
							<picture>
								<source media="(prefers-color-scheme: dark)" srcSet={`/images/ui/lock_white.svg`} />
								<img width="24px" src={`/images/ui/lock.svg`} alt="Ikona zamknutí" />
							</picture>
						) : (
							<picture>
								<source
									media="(prefers-color-scheme: dark)"
									srcSet={`/images/ui/unlock_white.svg`}
								/>
								<img width="24px" src={`/images/ui/unlock.svg`} alt="Ikona odemknutí" />
							</picture>
						)}
					</span>
				);
			},
		},
	};

	const sortFns = {
		USERNAME: array => array.sort((a, b) => a.username.localeCompare(b.username)),
		FORENAME: array => array.sort((a, b) => a.forename.localeCompare(b.forename)),
		SURNAMES: array => array.sort((a, b) => a.surnames.localeCompare(b.surnames)),
		UNIT: array => array.sort((a, b) => a.unit.name.localeCompare(b.unit.name)),
		STATUS: array => array.sort((a, b) => +b.active - +a.active),
		ROLES: array => array.sort((a, b) => a.roles.join(", ").localeCompare(b.roles.join(", "))),
	};

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isAdmin } = useRoles();

	if (users === null) return <Loading />;

	return (
		<main>
			<h2>
				<picture>
					<source srcSet="/images/icons/user_color.svg" />
					<img width="36px" src="/images/icons/user_color.svg" alt="Ikona uživatele" />
				</picture>
				Uživatelé
				<menu>
					{isAdmin ? (
						<button
							className={cs("action_button", { active: toggle })}
							onClick={() => setToggle(prevState => !prevState)}>
							{toggle ? "Zavřít formulář" : "Přidat uživatele"}
						</button>
					) : null}
				</menu>
			</h2>
			{toggle && <UserForm initialValues={users[editing]} id={editing} key={formKey} />}
			<CustomTable
				title="Seznam všech uživatelů"
				layout="minmax(12rem, 1fr) minmax(9rem, 1fr) minmax(7rem, 1fr) minmax(7rem, 1fr) minmax(16rem, 1fr) minmax(6rem, 1fr)"
				openUrl={user => `/uzivatele/${user.id}`}
				data={data}
				columns={columns}
				sortFns={sortFns}
				actions={["edit", "lock", "open"]}
				actionDefinitions={actionDefinitions}
				setEditing={setEditing}
			/>
		</main>
	);
}
