import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Login from "./components/PopForm/Login";
import Header from "./components/Header";
import Aside from "./components/Aside";
import Notifications from "./components/Notifications";
import Footer from "./components/Footer";
import ScrollToTop from "components/ScrollToTop";

import { notify } from "../../redux/settingsSlice";
import { getData } from "../../redux/dataSlice";

import "./style.sass";
import Init from "./components/PopForm/Init";

/**
 * Root component of the application responsible for rendering the main layout.
 * Displays the login form or initialization form if the default password is used
 * or if no records have been added to the app yet.
 * @returns {JSX.Element} The JSX element representing the Root component.
 */
export default function Root() {
	const { credentials, initForm } = useSelector(state => state.settings);
	const dispatch = useDispatch();

	// State to manage login status
	const [login, setLogin] = useState("NEW");

	/**
	 * Checks if the token is valid.
	 * @param {Object} credentials - User credentials.
	 * @returns {string} The login status: "NEW", "LOGGED", or "EXPIRED".
	 */
	const isTokenValid = credentials => {
		if (!credentials || !credentials.token || !credentials.expiration) return "NEW";
		return credentials.expiration > Date.now() ? "LOGGED" : "EXPIRED";
	};

	// Display notification if session expires
	useEffect(() => {
		if (login === "EXPIRED") {
			dispatch(notify({ message: "Sezení expirovalo.", type: "neutral" }));
		}
	}, [login, dispatch]);

	// Check token expiration periodically
	useEffect(() => {
		const handleTokenExpiration = () => setLogin(isTokenValid(credentials));

		handleTokenExpiration();

		const timer = setInterval(() => {
			handleTokenExpiration();
		}, 2500);

		return () => {
			clearInterval(timer);
		};
	}, [credentials]);

	// Fetch data if the user is logged in
	useEffect(() => {
		if (login === "LOGGED") dispatch(getData());
	}, [login, dispatch]);

	return (
		<React.Fragment>
			{/* Scroll to top of window for any path is changed */}
			<ScrollToTop />
			{/* Display initialization form if needed */}
			{initForm > -1 && <Init defaultFormIndex={initForm} />}
			{/* Display login form if not logged in */}
			{login !== "LOGGED" && <Login />}
			{/* Display notifications */}
			<Notifications />
			<div id="page_root" className="page">
				<Header />
				<Aside />
				<Outlet />
			</div>
			<Footer />
		</React.Fragment>
	);
}
