import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { read } from "../redux/settingsSlice";

/**
 * Custom hook for managing notifications displayed after an action is dispatched.
 * @param {string} category - The category of notifications to filter.
 * @returns {Array} An array of filtered notifications to be displayed.
 */
export default function useNotifications(category = "all") {
	const dispatch = useDispatch();

	// Get notifications from Redux store
	const { notifications } = useSelector(state => state.settings);

	// Filter notifications based on category
	const filtered = notifications.filter(
		notification =>
			(category === "all" && notification.category === undefined) ||
			notification.category === category
	);

	// Effect to automatically remove notifications after a certain duration
	useEffect(() => {
		if (filtered.length) {
			// Calculate duration based on the length of the first notification's message
			const duration = Math.max(2500, filtered[0].message.length * 25);

			// Set a timeout to dispatch the 'read' action after the duration
			const timeout = setTimeout(() => {
				dispatch(read());
			}, duration);

			// Cleanup function to clear the timeout when the component unmounts or the filtered notifications change
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [filtered, dispatch]);

	// Return the filtered notifications to be displayed
	return filtered;
}
