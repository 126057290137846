import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRowSelect, SelectTypes } from "@table-library/react-table-library/select";
import moment from "moment";
import cs from "classnames";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";
import Move from "pages/Root/components/PopForm/Move";
import ItemForm from "pages/Root/components/Form/ItemForm";

import useEditForm from "hooks/useEditForm";
import useRoles from "hooks/useRoles";

export default function Compressor() {
	const { items, compressors, placements, places } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (compressors === null) return;

		const newData = Object.values(compressors).map(compressor => {
			const newestPlacement = Object.values(placements || {})
				.filter(placement => placement.items.find(url => url === compressor.item))
				.sort((a, b) =>
					moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
						? 1
						: -1
				)[0];
			return {
				...compressor,
				item: items?.[compressor.item],
				located: places?.[newestPlacement?.located],
			};
		});
		setData({
			nodes: newData.sort((a, b) => {
				if (a.item?.label === undefined) return 1;
				else if (b.item?.label === undefined) return -1;
				else a.item.label.localeCompare(b.item.label);
			}),
		});
	}, [compressors, items, placements, places]);

	const select = useRowSelect(
		data,
		{},
		{
			rowSelect: SelectTypes.MultiSelect,
		}
	);
	const selected = useMemo(() => {
		if (select.state.id) return [select.state.id];
		return [...select.state.ids];
	}, [select]);

	const columns = [
		{
			label: "Předmět (S/N)",
			search: compressor => compressor.item?.label || "-",
			renderCell: compressor => (
				<Link to={`/predmety/${compressor.item?.id || ""}`}>{compressor.item?.label || "-"}</Link>
			),
			sort: { sortKey: "ITEM" },
		},
		{
			label: "Místo",
			search: compressor => compressor.located?.name || "",
			renderCell: compressor => (
				<Link to={`/mista?hledat=${compressor.located?.name || ""}`}>
					{compressor.located?.name || ""}
				</Link>
			),
			sort: { sortKey: "PLACE" },
		},
	];

	const sortFns = {
		ITEM: array => array.sort((a, b) => a.item?.label.localeCompare(b.item?.label)),
		PLACE: array => array.sort((a, b) => a.located?.name.localeCompare(b.located?.name)),
	};

	const addForm = useEditForm();

	const [moveToggle, setMoveToggle] = useState(false);

	const { isManipulator, isAdmin } = useRoles();

	if (compressors === null) return <Loading />;

	return (
		<React.Fragment>
			{moveToggle && (
				<Move
					selected={selected.map(
						id => Object.values(compressors).find(compressor => compressor.id === id).item
					)}
					setMoveToggle={setMoveToggle}
				/>
			)}
			<main>
				<h2>
					<picture>
						<source srcSet="/images/icons/compressor_color.svg" />
						<img width="36px" src="/images/icons/compressor_color.svg" alt="Ikona komporesoru" />
					</picture>
					Kompresory
					<menu>
						{isManipulator ? (
							<button
								className={cs("action_button", { inactive: !selected.length })}
								onClick={() => {
									if (selected.length) setMoveToggle(prevState => !prevState);
								}}>
								Přemístit
							</button>
						) : null}
						{isAdmin ? (
							<button
								className={cs("action_button", { active: addForm.toggle })}
								onClick={() => addForm.setToggle(prevState => !prevState)}>
								{addForm.toggle ? "Zavřít formulář" : "Přidat kompresor"}
							</button>
						) : null}
					</menu>
				</h2>
				{addForm.toggle && (
					<ItemForm key={addForm.formKey} initialValues={{ type: "COMPRESSOR" }} />
				)}
				<CustomTable
					title="Seznam všech komporesorů"
					layout="minmax(16rem, 1fr) minmax(8rem, 1fr)"
					data={data}
					openUrl={compressor => `/predmety/${compressor.item?.id}`}
					deleteUrl={compressor => compressor.item?.["@id"]}
					columns={columns}
					sortFns={sortFns}
					select={select}
					actions={["delete", "open"]}
				/>
			</main>
		</React.Fragment>
	);
}
