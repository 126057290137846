import React from "react";
import PropTypes from "prop-types";

import FillingForm from "../../Form/FillingForm";

// Prop types for the Fill component
Fill.propTypes = {
	selected: PropTypes.array, // Array of selected bottle ids to fill
	setFillToggle: PropTypes.func, // Function to toggle fill form visibility
};

// Pop Form using the Filling Form for an easier filling bottles
export default function Fill({ selected = [], setFillToggle }) {
	return (
		<div className="pop_form">
			<section>
				<header>
					<h1>Naplnit {selected.length > 1 ? "láhve" : "láhev"}</h1>
					{/* Close button */}
					<button onClick={() => setFillToggle(false)}>
						<picture>
							{/* Icon for close button */}
							<source media="(prefers-color-scheme: dark)" srcSet="/images/ui/close_white.svg" />
							<img width="18px" src="/images/ui/close.svg" alt="Ikona zavření" />
						</picture>
					</button>
				</header>
				<FillingForm filling={selected} withHeader={false} setClose={setFillToggle} />
			</section>
		</div>
	);
}
