import { createSlice } from "@reduxjs/toolkit";

import client from "../client"; // Importing the client function for making HTTP requests
import { unauthorized, notify, setInitForm } from "./settingsSlice"; // Importing action creators from settingsSlice

// Function to set empty values to null in an object
export function setEmptyValuesToNull(values) {
	return Object.entries(values).reduce((obj, [key, value]) => {
		obj[key] = value === "" ? null : value;
		return obj;
	}, {});
}

// Function to handle request errors
export function handleRequestError(dispatch, error, entity) {
	switch (error.response.data.detail) {
		// Handle error when a compressor with the same item ID already exists
		case "An exception occurred while executing a query: SQLSTATE[23000]: Integrity constraint violation: 19 UNIQUE constraint failed: compressor.item_id":
			dispatch(
				notify({
					message: "Tento předmět je již jiným kompresorem!",
					type: "error",
					category: entity,
				})
			);
			break;
		// Handle error when plain password is blank during user creation
		case "plainPassword: This value should not be blank.":
			dispatch(
				notify({
					message: "Při vytváření uživatele je nutné vyplnit heslo!",
					type: "error",
					category: entity,
				})
			);
			break;
		// Handle error when a user with the same username already exists
		case "An exception occurred while executing a query: SQLSTATE[23000]: Integrity constraint violation: 19 UNIQUE constraint failed: user.username":
			dispatch(
				notify({
					message: "Uživatel s tímto uživatelským jménem již existuje!",
					type: "error",
					category: entity,
				})
			);
			break;
		// Handle error when a bottle with the same item ID already exists
		case "An exception occurred while executing a query: SQLSTATE[23000]: Integrity constraint violation: 19 UNIQUE constraint failed: bottle.item_id":
			dispatch(
				notify({
					message: "Tento předmět je již svázán s jinou lahví!",
					type: "error",
					category: entity,
				})
			);
			break;
		// Default case: handle unauthorized error
		default:
			dispatch(unauthorized());
			break;
	}
}

// Async action to create an object
export const createObject =
	({ entity, values, useCategory = true }) =>
	async dispatch => {
		try {
			// Convert empty values to null
			const withNullValues = setEmptyValuesToNull(values);
			// Send a POST request to create the object
			const objectResponse = await client(`/api/${entity}`, "post", withNullValues);
			dispatch(saveObject({ entity, object: objectResponse.data }));

			// Create corresponding comporessor or bottle entity
			if (entity === "items") {
				if (values.type === "COMPRESSOR") {
					const compressorResponse = await client("/api/compressors", "post", {
						item: objectResponse.data["@id"],
					});
					dispatch(saveObject({ entity: "compressors", object: compressorResponse.data }));
				} else if (values.type === "BOTTLE") {
					const bottleResponse = await client("/api/bottles", "post", {
						item: objectResponse.data["@id"],
					});
					dispatch(saveObject({ entity: "bottles", object: bottleResponse.data }));
				}
			}

			// Dispatch a success notification
			dispatch(
				notify({
					message: "Úspěšně přidán záznam!",
					type: "success",
					category: useCategory ? entity : undefined,
				})
			);
		} catch (error) {
			// Handle errors
			handleRequestError(dispatch, error, entity);
		}
	};

// Async action to update an object
export const updateObject =
	({ entity, id, values, useCategory = true }) =>
	async dispatch => {
		try {
			// Convert empty values to null
			const withNullValues = setEmptyValuesToNull(values);
			// Send a PATCH request to update the object
			const { data } = await client(id, "patch", withNullValues);
			dispatch(saveObject({ entity, object: data }));
			// Dispatch a success notification
			// Some object have the success notification displayed below the edit form, the the useCategory is true
			dispatch(
				notify({
					message: `Úspěšně upraven${translateEntity(entity)} (${data.id}).`,
					type: "success",
					category: useCategory ? entity : undefined,
				})
			);
		} catch (error) {
			// Handle errors
			handleRequestError(dispatch, error, entity);
		}
	};

// Function to translate entity names for notification messages
export const translateEntity = entity => {
	// Translation mapping for entity names
	const translates = {
		users: " uživatel",
		units: "a jednotka",
		places: "o místo",
		items: " předmět",
		fillings: "o plnění",
		bottles: "a láhev",
		compressors: " kompresor",
		revisions: "a revize",
		usages: "o použití",
		placements: "o umístění",
	};

	// Return translated entity name if found, otherwise return original entity name
	return translates[entity] || entity;
};

// Async action to delete an object
export const deleteObject = url => async dispatch => {
	// Parse entity and ID from URL
	const pattern = /^\/api\/(\w+)\/(\d+)$/;
	const matches = url.match(pattern);
	const entity = matches[1];
	const id = matches[2];

	try {
		// Send a DELETE request to delete the object
		await client(url, "delete");
		dispatch(removeObject({ entity, url }));
		dispatch(
			notify({
				message: `Úspěšně smazán${translateEntity(entity)} (${id}).`,
				type: "success",
			})
		);
	} catch (error) {
		// Handle errors, set user not to be authorized, for example if his permissions has changed
		dispatch(unauthorized());
	}
};

// Async action to fetch data for all entities
export const getData = () => async dispatch => {
	const entities = [
		"users",
		"units",
		"places",
		"items",
		"fillings",
		"bottles",
		"compressors",
		"revisions",
		"usages",
		"placements",
	];
	try {
		// Fetch data for all entities in parallel
		await Promise.all(
			entities.map(async entity => {
				const { data } = await client(`/api/${entity}`);
				dispatch(saveData({ data, entity }));

				// If only admin is present, open the init form
				if (entity === "users" && data["hydra:member"].length === 1) dispatch(setInitForm(1));

				// Or if non item is present
				if (entity === "items" && data["hydra:member"].length === 0) dispatch(setInitForm(1));
			})
		);
	} catch (error) {
		// Handle errors
		dispatch(unauthorized());
	}
};

// Define the data slice
const dataSlice = createSlice({
	name: "data",
	initialState: {
		users: null,
		units: null,
		places: null,
		items: null,
		fillings: null,
		bottles: null,
		compressors: null,
		revisions: null,
		usages: null,
		placements: null,
	},
	reducers: {
		// Reducer to save an object to the state
		saveObject: (state, action) => {
			const { entity, object } = action.payload;
			// Save the object in the state under the entity's key
			state[entity][object["@id"]] = object;
		},
		/**
		 * Removes an entity from the client-side state.
		 * This function cascades through related entities and removes any dependencies.
		 * @param {Object} state - The current state of the Redux store.
		 * @param {Object} action - The Redux action containing the entity and URL to remove.
		 */
		removeObject: (state, action) => {
			const { entity, url } = action.payload;

			// Cascading relationships where removing an entity may also remove related entities
			const cascades = {
				items: { bottles: "item", compressors: "item" },
				places: { placements: "located" },
				compressors: { fillings: "filledBy" },
				bottles: { fillings: "bottle" },
			};

			// Relationships where removing an entity may update related entities
			const relations = {
				units: { items: "belongsTo", users: "unit" },
				items: { revisions: "items", placements: "items", usages: "items", units: "items" },
				revisions: { users: "revisions", items: "revisions" },
				placements: { users: "placements", places: "placements", items: "placements" },
				usages: { users: "usages", items: "usages" },
				fillings: { users: "fillings", bottles: "fillings", compressors: "fillings" },
			};

			// Array to track entities to be removed, initialized with the initial entity and URL
			const toBeRemoved = [{ entity, url }];

			// Loop through toBeRemoved array until it's empty
			while (toBeRemoved.length) {
				// Extract the next entity and URL to remove from the array
				const { entity, url } = toBeRemoved.pop();

				// Cascade removals based on defined relationships
				Object.entries(cascades[entity] || {}).forEach(([type, attribute]) => {
					// Check related entities and add them to the removal list if they depend on the current URL
					Object.entries(state[type]).forEach(([relatedUrl, object]) => {
						if (object[attribute] === url) toBeRemoved.push({ entity: type, url: relatedUrl });
					});
				});

				// Update relationships without removing entities
				Object.entries(relations[entity] || {}).forEach(([type, attribute]) => {
					Object.entries(state[type]).forEach(([relatedUrl, object]) => {
						// Update relationships to remove dependency on the deleted URL
						if (state[type][relatedUrl][attribute] === url)
							state[type][relatedUrl][attribute] = null;
						// Remove the URL from array attributes if applicable
						else if (Array.isArray(object[attribute]))
							state[type][relatedUrl][attribute] = state[type][relatedUrl][attribute].filter(
								object => object !== url
							);
					});
				});

				// Remove the entity from the state
				delete state[entity][url];
			}
		},
		// Reducer to save data fetched for an entity
		saveData: (state, action) => {
			const { entity, data } = action.payload;

			state[entity] = {};

			// Set only the values in :member to Redux store
			data["hydra:member"].forEach(element => {
				state[entity][element["@id"]] = element;
			});
		},
	},
});

// Extract action creators and reducer from data slice
export const { saveData, saveObject, removeObject } = dataSlice.actions;

export default dataSlice.reducer; // Export the reducer
