import React from "react";

import Navigation from "components/Navigation";

import "./style.sass";

export default function Footer() {
	return (
		<footer id="page_root_component_footer">
			<h2>Webový provozní deník kompresoru</h2>
			<Navigation darkBackground={true} />
			<p>
				Bakalářský projekt Martina Frieda na Fakultě informačních technologií ČVUT pod vedením doc.
				RNDr. Dušan Knop, Ph.D.
			</p>
		</footer>
	);
}
