import { configureStore } from "@reduxjs/toolkit";

import settingsReducer from "./redux/settingsSlice";
import dataReducer from "./redux/dataSlice";

export default configureStore({
	reducer: {
		data: dataReducer,
		settings: settingsReducer,
	},
	devTools: true,
});
