import { useSelector } from "react-redux";

/**
 * Custom hook for managing user roles based on the current session.
 * @returns {Object} An object containing boolean values indicating whether the user has specific roles.
 */
export default function useRoles() {
	// Get session data from Redux store
	const session = useSelector(state => state.settings.session);

	// Determine if the user has admin role
	const isAdmin = session?.roles.includes("ROLE_ADMIN");

	// Determine if the user has technician role (or admin role)
	const isTechnician = session?.roles.includes("ROLE_TECHNICIAN") || isAdmin;

	// Determine if the user has manipulator role (or admin role)
	const isManipulator = session?.roles.includes("ROLE_MANIPULATOR") || isAdmin;

	// Return an object with boolean values representing user roles
	return { isAdmin, isTechnician, isManipulator };
}
