import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Loading from "components/Loading";

import RevisionTable from "pages/Root/components/Table/RevisionTable";
import useEditForm from "hooks/useEditForm";
import RevisionForm from "pages/Root/components/Form/RevisionForm";
import useRoles from "hooks/useRoles";

RevisionDetail.propTypes = {
	revisions: PropTypes.array,
	about: PropTypes.string,
	item: PropTypes.object,
	user: PropTypes.object,
};

export default function RevisionDetail({ revisions, about, user, item }) {
	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isTechnician } = useRoles();

	if (!revisions) return <Loading />;

	return (
		<React.Fragment>
			<section id="revize" className="noBreak">
				<h3>
					Revize
					<menu>
						{isTechnician ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => setToggle(prevState => !prevState)}>
								{toggle ? "Zavřít formulář" : "Přidat revizi"}
							</button>
						) : null}
					</menu>
				</h3>
				{about ? <p>{about}</p> : null}
				{toggle && (
					<RevisionForm
						initialValues={revisions.find(revision => revision["@id"] === editing)}
						id={editing}
						key={formKey}
						item={item?.["@id"]}
						user={user}
					/>
				)}
			</section>
			<RevisionTable
				title="Seznam provedených revizí na předmětu"
				revisions={revisions}
				defaultPageSize={10}
				setEditing={setEditing}
			/>
		</React.Fragment>
	);
}
