import React from "react";

export default function Information() {
	return (
		<React.Fragment>
			<h3>Základní informace o aplikaci</h3>
			<p>
				Tato aplikace nabízí správu předmětů a uživatelů pro hasičské sbory. Můžete si zde vytvořit
				i vlastní kategorii vedle výchozích (kompresor, láhev, maska). U těchto předmětů je následně
				možno zaznamenávat jejich užití uživatelem, revize, nebo místo, kde se nachází. Láhve jsou
				speciálním typem, u kterého lze zaznamenávat plnění nějakým kompresorem.
			</p>
			<p>
				Místa jsou entita, může sloužit i pro ostatní označení předmětu, který je například
				momentálně na Revizi. Pokud například plníte lahve jiným sborům, pak je praktické vytvořit
				místa pro jejich Jednotky, až láhev vrátíte zpět. Typickým užitím jsou pak místa, kde chcete
				nechávat vaše předměty, jako Tatra, Sklad 1 atd.
			</p>
			<p>
				Pro uživatele můžete volit ze 3 rolí. Role Admin opravňuje uživatele ke všem úpravám. Role
				Technik dovoluje uživateli upravovat a přidávat Revize předmětu a Plnění lahví. Role
				Manipulátor dovoluje vytvářet a upravovat umístění předmětu. Každý přihlášený uživatel smí
				prohlížet všechny záznamy v aplikaci.
			</p>
		</React.Fragment>
	);
}
