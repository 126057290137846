import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import cs from "classnames";

import { createObject, updateObject } from "../../../../../redux/dataSlice";
import useNotifications from "hooks/useNotifications";
import CustomSelect from "components/CustomSelect";
import { filterProperties } from "pages/Root/helpers";

UsageForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	item: PropTypes.string,
	user: PropTypes.object,
};

export default function UsageForm({ initialValues, id, item, user }) {
	const session = useSelector(state => state.settings.session);

	let defaultValues = {
		usedBy: user?.["@id"] || session["@id"],
		date: moment().format("YYYY-MM-DD"),
		items: [],
	};
	if (item) defaultValues.items = [item];

	// Convert initial dates to Moment objects
	const defaultDate = initialValues?.date
		? moment(initialValues.date).format("YYYY-MM-DD")
		: moment().format("YYYY-MM-DD");

	const mergedDefaults = {
		...defaultValues,
		...initialValues,
		date: defaultDate,
	};

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		usedBy: Yup.string().required("Povinné pole"),
		date: Yup.date().required("Povinné pole"),
		items: Yup.array()
			.of(Yup.string().required("Povinné pole"))
			.min(1, "Musí obsahovat minimálně jeden předmět"),
	});

	const { users, items } = useSelector(state => state.data);
	const userOptions = Object.entries(users)?.map(([id, user]) => ({
		label: user.username,
		value: id,
	}));

	const itemOptions = Object.entries(items)?.map(([id, item]) => ({
		label: item?.label || "-",
		value: id,
	}));

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("usages");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	return (
		<section>
			<h4>{id ? "Upravit" : "Vytvořit nové"} použití</h4>
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					dispatch(
						id
							? updateObject({ entity: "usages", id, values })
							: createObject({ entity: "usages", values })
					);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className="form">
						<label>
							Použil
							<Field
								name="usedBy"
								defaultOptions={userOptions}
								component={CustomSelect}
								placeholder="Vyberte uživatele..."
								isMulti={false}
							/>
							<ErrorMessage name="usedBy" component="span" className="error-message" />
						</label>
						<label>
							Datum
							<Field type="date" name="date" />
							<ErrorMessage name="date" component="span" className="error-message" />
						</label>
						<label>
							Předměty
							<Field
								name="items"
								defaultOptions={itemOptions}
								component={CustomSelect}
								placeholder="Vyberte předměty..."
								isMulti={true}
							/>
							<ErrorMessage name="items" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Vytvořit"}
						</button>
					</Form>
				)}
			</Formik>
		</section>
	);
}
