import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { useDispatch } from "react-redux";

import UserForm from "../../Form/UserForm";
import AdminPasswordForm from "./components/AdminPasswordForm";
import ItemForm from "../../Form/ItemForm";
import useNotifications from "hooks/useNotifications";
import Information from "./components/Information";
import { read, setInitForm } from "../../../../../redux/settingsSlice";

import "./style.sass";

Init.propTypes = {
	defaultFormIndex: PropTypes.number,
};

/**
 * Pop Form to init values in the app
 * Display if default password is still used, or none users nor items are added
 * @param {number} defaultFormIndex - The index of the default form
 */
export default function Init({ defaultFormIndex }) {
	// State to manage the current form index
	const [formIndex, setFormIndex] = useState(defaultFormIndex);

	// Redux dispatch hook
	const dispatch = useDispatch();

	// Notifications for user form, which moves the from from 0 or 2 page
	const usersNotifications = useNotifications("users");
	useEffect(() => {
		// If user form and successful notification, read and move to next form
		if (
			(formIndex === 0 || formIndex === 2) &&
			usersNotifications.length &&
			usersNotifications.find(notification => notification.type === "success")
		) {
			dispatch(read());
			setFormIndex(formIndex + 1);
		}
	}, [usersNotifications]);

	// Notifications for item form, which is page 3
	const itemsNotifications = useNotifications("items");
	useEffect(() => {
		// If item form and successful notification, read and close init form
		if (
			formIndex === 3 &&
			itemsNotifications.length &&
			itemsNotifications.find(notification => notification.type === "success")
		) {
			dispatch(read());
			dispatch(setInitForm(-1));
		}
	}, [itemsNotifications]);

	return (
		<div className="pop_form page_root_component_init">
			<section>
				<header>
					<h1>Úvodní formulář</h1>
					{/* Button to close init form */}
					<button onClick={() => dispatch(setInitForm(-1))}>
						<picture>
							<source media="(prefers-color-scheme: dark)" srcSet="/images/ui/close_white.svg" />
							<img width="18px" src="/images/ui/close.svg" alt="Ikona zavření" />
						</picture>
					</button>
				</header>
				{/* Render different forms based on formIndex */}
				{formIndex === 0 && <AdminPasswordForm />}
				{formIndex === 1 && <Information />}
				{formIndex === 2 && <UserForm />}
				{formIndex === 3 && <ItemForm initialValues={{ type: "COMPRESSOR" }} />}
				<menu>
					{/* Navigation buttons */}
					<button
						className={cs({ disabled: formIndex === 0 })}
						onClick={() => setFormIndex(prevState => Math.max(prevState - 1, 0))}>
						{"<"} zpět
					</button>
					<span></span>
					{formIndex < 3 ? (
						<button onClick={() => setFormIndex(prevState => Math.min(prevState + 1, 3))}>
							přeskočit {">"}
						</button>
					) : (
						<button onClick={() => dispatch(setInitForm(-1))}>zavřít</button>
					)}
				</menu>
			</section>
		</div>
	);
}
