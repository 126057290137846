import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Loading from "components/Loading";

import PlacementTable from "pages/Root/components/Table/PlacementTable";
import useEditForm from "hooks/useEditForm";
import PlacementForm from "pages/Root/components/Form/PlacementForm";
import useRoles from "hooks/useRoles";

PlacementDetail.propTypes = {
	placements: PropTypes.array,
	about: PropTypes.string,
	item: PropTypes.object,
	user: PropTypes.object,
};

export default function PlacementDetail({ placements, about, user, item }) {
	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isManipulator } = useRoles();

	if (!placements) return <Loading />;

	return (
		<React.Fragment>
			<section id="umisteni" className="noBreak">
				<h3>
					Umístění
					<menu>
						{isManipulator ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => setToggle(prevState => !prevState)}>
								{toggle ? "Zavřít formulář" : "Přidat umístění"}
							</button>
						) : null}
					</menu>
				</h3>
				{about ? <p>{about}</p> : null}
				{toggle && (
					<PlacementForm
						initialValues={placements.find(placement => placement["@id"] === editing)}
						id={editing}
						key={formKey}
						moving={[item?.["@id"]]}
						user={user}
					/>
				)}
			</section>
			<PlacementTable
				title="Seznam provedených umístění předmětu"
				placements={placements}
				defaultPageSize={10}
				setEditing={setEditing}
			/>
		</React.Fragment>
	);
}
