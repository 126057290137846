import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import PropTypes from "prop-types";

import "./style.sass";

CustomSelect.propTypes = {
	placeholder: PropTypes.string, // Placeholder text for the select input
	field: PropTypes.object.isRequired, // Field object from Formik
	form: PropTypes.object.isRequired, // Form object from Formik
	defaultOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired, // Label for each option
			value: PropTypes.string.isRequired, // Value for each option
		})
	).isRequired, // Array of default options for the select
	isMulti: PropTypes.bool, // Boolean indicating whether the select is multi-select
	isClearable: PropTypes.bool, // Boolean indicating whether the select is clearable
};

/**
 * Component for rendering custom select input using react-select library.
 * Supports single and multi-select options with or without the ability to clear selections.
 * @param {Object} props - Component props
 * @param {string} [props.placeholder] - Placeholder text for the select input
 * @param {Object} props.field - Field object from Formik
 * @param {Object} props.form - Form object from Formik
 * @param {Array} props.defaultOptions - Array of default options for the select
 * @param {boolean} [props.isMulti=false] - Boolean indicating whether the select is multi-select
 * @param {boolean} [props.isClearable=false] - Boolean indicating whether the select is clearable
 * @returns {JSX.Element} - Rendered CustomSelect component
 */
export default function CustomSelect({
	placeholder,
	field,
	form,
	defaultOptions,
	isMulti = false,
	isClearable = false,
}) {
	// State for managing options and selected value
	const [options, setOptions] = useState(defaultOptions);
	const [value, setValue] = useState(
		isMulti
			? options?.filter(option => field.value?.indexOf(option.value) >= 0) || []
			: options?.find(option => option.value === field.value) || ""
	);

	// Function to create a new option
	const createOption = label => ({
		label,
		value: label.toLowerCase().replace(/\W/g, ""),
	});

	// Function to handle change in select value
	const handleChange = option => {
		setValue(option);
		form.setFieldValue(field.name, isMulti ? option.map(item => item.value) : option?.value);
	};

	// Function to handle creation of new option
	const handleCreate = value => {
		const newOption = createOption(value);
		setOptions(prev => [...prev, newOption]);
		handleChange(newOption);
	};

	// Render CreatableSelect component if isClearable is true, otherwise render Select component
	if (isClearable)
		return (
			<CreatableSelect
				className="component_customselect"
				name={field.name}
				placeholder={placeholder}
				isMulti={isMulti}
				isClearable={isClearable}
				onCreateOption={handleCreate}
				onChange={handleChange}
				options={options}
				value={value}
				formatCreateLabel={inputValue => `Vytvořit "${inputValue}"`}
			/>
		);

	return (
		<Select
			className="component_customselect"
			name={field.name}
			placeholder={placeholder}
			isMulti={isMulti}
			onChange={handleChange}
			options={options}
			value={value}
		/>
	);
}
