import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import cs from "classnames";

import Loading from "components/Loading";
import { translateRole } from "pages/Root/helpers";
import useEditForm from "hooks/useEditForm";
import UserForm from "pages/Root/components/Form/UserForm";
import FillingDetail from "pages/Root/components/Detail/FillingDetail";
import RevisionDetail from "pages/Root/components/Detail/RevisionDetail";
import UsageDetail from "pages/Root/components/Detail/UsageDetail";
import PlacementDetail from "pages/Root/components/Detail/PlacementDetail";
import useRoles from "hooks/useRoles";

export default function UserDetail() {
	let { userId } = useParams();
	const navigate = useNavigate();

	const { users, units, fillings, revisions, usages, placements } = useSelector(
		state => state.data
	);

	const [user, setUser] = useState(null);
	useEffect(() => setUser(users?.[`/api/users/${userId}`]), [users, userId]);

	useEffect(
		() =>
			setUser(prevState => {
				if (typeof user?.unit === "string") return { ...prevState, unit: units?.[user?.unit] };
				return prevState;
			}),
		[user?.unit, units]
	);

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isAdmin } = useRoles();

	if (Object.values(users || {}).length === 0) return <Loading />;

	if (Object.values(users || {}).length && user?.["@id"] === undefined) {
		return (
			<main className="empty_message">
				<h1>Tento uživatel nebyl nalezen.</h1>
			</main>
		);
	}

	return (
		<main>
			<header>
				<menu>
					<button className="link" onClick={() => navigate(-1)}>
						⇦ Zpět
					</button>
				</menu>
				<h2>
					Uživatel {user.forename} {user.surnames}
					<nav>
						<a href="#revize">Revize</a>
						<a href="#pouziti">Použití</a>
						<a href="#umisteni">Umístění</a>
					</nav>
				</h2>
			</header>
			<section>
				<h3>
					Základní informace
					<menu>
						{isAdmin ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => {
									setToggle(prevState => !prevState);
									setEditing(user["@id"]);
								}}>
								{toggle ? "Zavřít formulář" : "Upravit"}
							</button>
						) : null}
					</menu>
				</h3>
				{toggle && (
					<UserForm initialValues={users[editing]} id={editing} key={formKey} subtitle={true} />
				)}
				<dl className="description_list">
					<div>
						<dt>Uživatelské jméno</dt>
						<dd>{user.username}</dd>
					</div>
					<div>
						<dt>Křestní jména</dt>
						<dd>{user.forename || "-"}</dd>
					</div>
					<div>
						<dt>Příjmení</dt>
						<dd>{user.surnames || "-"}</dd>
					</div>
					<div>
						<dt>Jednotka</dt>
						<dd>
							{user.unit?.name ? (
								<Link to={`/jednotky?hledat=${user.unit?.name}`}>
									{user.unit?.name || user.unit}
								</Link>
							) : (
								"-"
							)}
						</dd>
					</div>
					<div>
						<dt>Status</dt>
						<dd>{user.active ? "aktivní" : "neaktivní"}</dd>
					</div>
					<div>
						<dt>Role</dt>
						<dd>{user.roles.map(role => translateRole(role)).join(", ")}</dd>
					</div>
				</dl>
			</section>
			<FillingDetail
				user={user}
				fillings={
					fillings && Object.values(fillings).filter(filling => filling.madeBy === user["@id"])
				}
				about="Zde je seznam všech plnění, které provedl tento uživatel."
			/>
			<RevisionDetail
				user={user}
				revisions={
					revisions && Object.values(revisions).filter(revision => revision.doneBy === user["@id"])
				}
				about="Zde jsou informace o všech revizích, které provedl tento uživatel."
			/>
			<UsageDetail
				user={user}
				usages={usages && Object.values(usages).filter(usage => usage.usedBy === user["@id"])}
				about="Zde jsou informace o všech použití tohoto uživatele."
			/>
			<PlacementDetail
				user={user}
				placements={
					placements &&
					Object.values(placements).filter(placement => placement.movedBy === user["@id"])
				}
				about="Zde jsou informace o všech umístění, které provedl tento uživatel."
			/>
		</main>
	);
}
