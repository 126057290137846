/**
 * Filters properties of an object based on the provided keys.
 * @param {Object} obj - The object to filter.
 * @param {string[]} keys - An array of keys to include in the filtered object.
 * @returns {Object} - The filtered object containing only the properties specified by the keys.
 */
export function filterProperties(obj, keys) {
	return Object.keys(obj).reduce((filteredObj, key) => {
		// If current key is present in allowed keys from arguments, add this property to the new object
		if (keys.includes(key)) filteredObj[key] = obj[key];

		return filteredObj;
	}, {});
}

/**
 * Translates a user role into its corresponding string representation.
 * @param {string} role - The role to translate.
 * @returns {string} - The translated role string.
 */
export const translateRole = role => {
	const roles = {
		ROLE_ADMIN: "admin",
		ROLE_TECHNICIAN: "technik",
		ROLE_MANIPULATOR: "manipulátor",
		ROLE_USER: "uživatel",
	};
	return roles?.[role] || role;
};

/**
 * Translates an item type into its corresponding string representation.
 * @param {string} type - The item type to translate.
 * @returns {string} - The translated type string.
 */
export const translateType = type => {
	const types = {
		COMPRESSOR: "kompresor",
		BOTTLE: "láhev",
		MASK: "maska",
	};
	return types?.[type] || type;
};
