import React from "react";
import PropTypes from "prop-types";

import PlacementForm from "../../Form/PlacementForm";

// Prop types for the Move component
Move.propTypes = {
	selected: PropTypes.array, // Array of selected items to move
	setMoveToggle: PropTypes.func, // Function to toggle move form visibility
};

// Pop Form using the Placement Form for an easier placing multiple items at once
export default function Move({ selected = [], setMoveToggle }) {
	return (
		<div className="pop_form">
			<section>
				<header>
					<h1>Přemístit předmět{selected.length > 1 ? "y" : ""}</h1>
					{/* Close button */}
					<button onClick={() => setMoveToggle(false)}>
						<picture>
							<source media="(prefers-color-scheme: dark)" srcSet="/images/ui/close_white.svg" />
							<img width="18px" src="/images/ui/close.svg" alt="Ikona zavření" />
						</picture>
					</button>
				</header>
				<PlacementForm moving={selected} withHeader={false} setClose={setMoveToggle} />
			</section>
		</div>
	);
}
