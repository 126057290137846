import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import moment from "moment";
import cs from "classnames";

import { createObject, updateObject } from "../../../../../redux/dataSlice";
import { filterProperties } from "pages/Root/helpers";
import useNotifications from "hooks/useNotifications";
import CustomSelect from "components/CustomSelect";

FillingForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	item: PropTypes.object,
	user: PropTypes.object,
	subtitle: PropTypes.bool,
	filling: PropTypes.array,
	withHeader: PropTypes.bool,
	setClose: PropTypes.func,
};

export default function FillingForm({
	initialValues,
	id = undefined,
	item = undefined,
	user = undefined,
	subtitle = false,
	filling = undefined,
	withHeader = true,
	setClose = () => {},
}) {
	const session = useSelector(state => state.settings.session);

	let defaultValues = {
		madeBy: user?.["@id"] || session["@id"],
		filledBy: item?.["@id"].includes("compressors") ? item["@id"] : "",
		bottle: item?.["@id"].includes("bottles") ? item["@id"] : "",
		pressure: "",
		start: "",
		finish: "",
	};
	if (filling) defaultValues.bottle = filling;

	// Convert initial dates to Moment objects
	const defaultStart = initialValues?.start
		? moment(initialValues.start).format("YYYY-MM-DDTHH:mm")
		: moment().subtract(3, "hours").format("YYYY-MM-DDTHH:mm");
	const defaultFinish = initialValues?.finish
		? moment(initialValues.finish).format("YYYY-MM-DDTHH:mm")
		: moment().format("YYYY-MM-DDTHH:mm");

	const mergedDefaults = {
		...defaultValues,
		...initialValues,
		start: defaultStart,
		finish: defaultFinish,
	};

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		madeBy: Yup.string().required("Povinné pole"),
		filledBy: Yup.string().required("Povinné pole"),
		bottle: Array.isArray(filling)
			? Yup.array().required("Povinné pole")
			: Yup.string().required("Povinné pole"),
		pressure: Yup.number().required("Povinné pole").positive("Musí být kladné číslo"),
		start: Yup.date().required("Povinné pole"),
		finish: Yup.date().min(Yup.ref("start"), "Konec musí být po začátku").required("Povinné pole"),
	});

	const { users, compressors, bottles, items } = useSelector(state => state.data);
	const userOptions = useMemo(() => {
		return Object.entries(users)?.map(([id, user]) => ({
			label: user.username,
			value: id,
		}));
	}, [users]);

	const compressorOptions = useMemo(() => {
		return Object.entries(compressors)?.map(([id, compressor]) => ({
			label: items?.[compressor.item]?.label || "-",
			value: id,
		}));
	}, [compressors, items]);

	const bottleOptions = useMemo(() => {
		return Object.entries(bottles)?.map(([id, bottle]) => ({
			label: items?.[bottle.item]?.label || "-",
			value: id,
		}));
	}, [bottles, items]);

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("fillings");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	const renderForm = () => {
		return (
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					if (Array.isArray(values.bottle)) {
						values.bottle.forEach(bottle => {
							dispatch(
								id
									? updateObject({ entity: "fillings", id, values: { ...values, bottle } })
									: createObject({
											entity: "fillings",
											values: { ...values, bottle },
											useCategory: withHeader,
										})
							);
						});
					} else
						dispatch(
							id
								? updateObject({ entity: "fillings", id, values })
								: createObject({ entity: "fillings", values, useCategory: withHeader })
						);
					setClose(false);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className={cs("form", { withoutHeader: !withHeader })}>
						<label>
							Uživatel
							<Field
								name="madeBy"
								defaultOptions={userOptions}
								component={CustomSelect}
								placeholder="Vyberte uživatele..."
								isMulti={false}
							/>
							<ErrorMessage name="madeBy" component="span" className="error-message" />
						</label>
						<label>
							Kompresor
							<Field
								name="filledBy"
								defaultOptions={compressorOptions}
								component={CustomSelect}
								placeholder="Vyberte kompresor..."
								isMulti={false}
							/>
							<ErrorMessage name="filledBy" component="span" className="error-message" />
						</label>
						<label>
							Láhev
							<Field
								name="bottle"
								defaultOptions={bottleOptions}
								component={CustomSelect}
								placeholder="Vyberte láhev..."
								isMulti={Array.isArray(filling)}
							/>
							<ErrorMessage name="bottle" component="span" className="error-message" />
						</label>
						<label>
							Tlak [bar]
							<Field type="number" name="pressure" style={{ width: "5rem" }} />
							<ErrorMessage name="pressure" component="span" className="error-message" />
						</label>
						<label>
							Začátek
							<Field type="datetime-local" name="start" />
							<ErrorMessage name="start" component="span" className="error-message" />
						</label>
						<label>
							Konec
							<Field type="datetime-local" name="finish" />
							<ErrorMessage name="finish" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Plnit"}
						</button>
					</Form>
				)}
			</Formik>
		);
	};

	if (!withHeader) return renderForm();

	return (
		<section>
			{subtitle ? (
				<h4>{id ? "Upravit" : "Vytvořit nové"} plnění</h4>
			) : (
				<h3>{id ? "Upravit" : "Vytvořit nové"} plnění</h3>
			)}
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			{renderForm()}
		</section>
	);
}
