import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Component that scrolls the page to the top whenever the route changes.
 * This ensures that when navigating to a new page or route, the viewport starts at the top.
 * @returns {null}
 */
export default function ScrollToTop() {
	// Get the current location (pathname) using the useLocation hook from react-router-dom
	const { pathname } = useLocation();

	// Use useEffect to perform a side effect when the pathname changes
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]); // Re-run the effect whenever the pathname changes

	return null;
}
