import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cs from "classnames";
import moment from "moment";

import Loading from "components/Loading";

import BottleForm from "pages/Root/components/Form/BottleForm";
import useEditForm from "hooks/useEditForm";
import FillingDetail from "../FillingDetail";
import useRoles from "hooks/useRoles";

BottleDetail.propTypes = {
	item: PropTypes.object,
};

export default function BottleDetail({ item }) {
	const { bottles, fillings } = useSelector(state => state.data);
	const [bottle, setBottle] = useState(null);

	useEffect(() => {
		if (bottles === null) return;

		if (Object.values(bottles).length) {
			setBottle(() =>
				Object.values(bottles).find(bottle => bottle.item === `/api/items/${item.id}`)
			);
		}
	}, [bottles]);

	const emplacedFillings = useMemo(() => {
		return Object.values(fillings || {})
			?.filter(filling => filling.bottle === bottle?.["@id"])
			.sort((a, b) =>
				moment(a.start, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.start, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			);
	}, [bottle?.fillings, fillings]);

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isAdmin } = useRoles();

	if (!bottle || !fillings) return <Loading />;

	return (
		<React.Fragment>
			<section>
				<h3>
					Informace lahve
					<menu>
						{isAdmin ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => {
									setToggle(prevState => !prevState);
									setEditing(bottle["@id"]);
								}}>
								{toggle ? "Zavřít formulář" : "Upravit"}
							</button>
						) : null}
					</menu>
				</h3>
				{toggle && (
					<BottleForm initialValues={bottles[editing]} id={editing} key={formKey} subpage={true} />
				)}
				<dl className="description_list">
					<div>
						<dt>Kapacita</dt>
						<dd>{bottle.capacity ? bottle.capacity + " l" : "-"}</dd>
					</div>
					<div>
						<dt>Plnící tlak</dt>
						<dd>{bottle.fillingPressure ? bottle.fillingPressure + " bar" : "-"}</dd>
					</div>
					<div>
						<dt>Plnící médium</dt>
						<dd>{bottle.fillingMedium || "-"}</dd>
					</div>
					<div>
						<dt>Poslední plnění</dt>
						<dd
							className={cs({
								red: moment(emplacedFillings?.[0]?.finish).isBefore(moment().subtract(6, "months")),
							})}>
							{emplacedFillings?.[0]
								? moment(emplacedFillings?.[0]?.finish).format("D. M. YYYY H:mm")
								: "Není žádné plnění"}
						</dd>
					</div>
					<div>
						<dt>Stav tlaku</dt>
						<dd>{emplacedFillings?.[0] ? emplacedFillings?.[0]?.pressure + " bar" : "-"}</dd>
					</div>
				</dl>
			</section>
			<FillingDetail
				item={bottle}
				fillings={
					fillings && Object.values(fillings).filter(filling => filling.bottle === bottle["@id"])
				}
				about="Zde je seznam všech plnění této lahve."
			/>
		</React.Fragment>
	);
}
