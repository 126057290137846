import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";

PlacementTable.propTypes = {
	title: PropTypes.string,
	placements: PropTypes.array,
	defaultPageSize: PropTypes.number,
	setEditing: PropTypes.func,
};

export default function PlacementTable({
	title = "Seznam všech umístění",
	placements = null,
	defaultPageSize = 30,
	setEditing,
}) {
	const { users, items, places } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (placements === null) return;

		const newData = placements.map(placement => {
			const newItems = placement.items.map(item => items?.[item]);
			return {
				...placement,
				movedBy: users?.[placement.movedBy],
				located: places?.[placement.located],
				items: newItems,
			};
		});
		setData({
			nodes: newData.sort((a, b) =>
				moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			),
		});
	}, [placements, users, items, places]);

	const columns = [
		{
			label: "Umístil",
			search: placement => placement.movedBy?.username || placement.movedBy,
			renderCell: placement => (
				<Link to={`/uzivatele/${placement.movedBy?.id || ""}`}>
					{placement.movedBy?.username || placement.movedBy}
				</Link>
			),
			sort: { sortKey: "MOVEDBY" },
		},
		{
			label: "Místo",
			search: placement => placement.located?.name || placement.located,
			renderCell: placement => (
				<Link to={`/mista?hledat=${placement.located?.name || ""}`}>
					{placement.located?.name || placement.located}
				</Link>
			),
			sort: { sortKey: "PLACE" },
		},
		{
			label: "Datum",
			renderCell: placement => placement.date && moment(placement.date).format("D. M. YYYY"),
			sort: { sortKey: "DATE" },
		},
		{
			label: "Předměty",
			search: placement => placement.items.map(item => item?.label || "-").join(", "),
			renderCell: placement =>
				placement.items.map((item, key) => (
					<React.Fragment key={key}>
						{key === 0 ? "" : ", "}
						<Link to={`/predmety/${item?.id || ""}`}>{item?.label || "-"}</Link>
					</React.Fragment>
				)),
			sort: { sortKey: "ITEMS" },
		},
	];

	const sortFns = {
		MOVEDBY: array => array.sort((a, b) => a.movedBy.username.localeCompare(b.movedBy.username)),
		PLACE: array => array.sort((a, b) => a.located.name.localeCompare(b.located.name)),
		DATE: array =>
			array.sort((a, b) =>
				moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
					? -1
					: 1
			),
		ITEMS: array =>
			array.sort((a, b) =>
				a.items
					.map(item => item.label)
					.join(", ")
					.localeCompare(b.items.map(item => item.label).join(", "))
			),
	};

	if (placements === null) return <Loading />;

	return (
		<CustomTable
			title={title}
			layout="minmax(6rem, 1fr) minmax(9rem, 1fr) minmax(9rem, 1fr) minmax(24rem, 1fr)"
			data={data}
			columns={columns}
			sortFns={sortFns}
			entity="placements"
			actions={["edit", "delete"]}
			defaultPageSize={defaultPageSize}
			setEditing={setEditing}
		/>
	);
}
