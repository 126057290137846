import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cs from "classnames";

import { createObject, updateObject } from "../../../../../redux/dataSlice";
import { filterProperties } from "pages/Root/helpers";
import useNotifications from "hooks/useNotifications";

BottleForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	subpage: PropTypes.bool,
};

export default function BottleForm({ initialValues, id = undefined, subpage = false }) {
	let defaultValues = {
		capacity: "",
		fillingPressure: "",
		fillingMedium: "",
	};
	const mergedDefaults = { ...defaultValues, ...initialValues };

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		capacity: Yup.number().nullable().positive("Musí být kladné číslo"),
		fillingPressure: Yup.number().min(0, "Plnící tlak musí být větší nebo rovný nule").nullable(),
		fillingMedium: Yup.string().nullable(),
	});

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("bottles");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	return (
		<section>
			{subpage ? (
				<h4>
					{id ? "Upravit" : "Vytvořit novou"} láhev {mergedDefaults.item?.label}
				</h4>
			) : (
				<h3>
					{id ? "Upravit" : "Vytvořit novou"} láhev {mergedDefaults.item?.label}
				</h3>
			)}
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					dispatch(
						id
							? updateObject({ entity: "bottles", id, values })
							: createObject({ entity: "bottles", values })
					);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className="form">
						<label>
							Kapacita [l]
							<Field type="number" name="capacity" style={{ width: "6rem" }} />
							<ErrorMessage name="capacity" component="span" className="error-message" />
						</label>
						<label>
							Plnící tlak [bar]
							<Field type="number" name="fillingPressure" style={{ width: "6rem" }} />
							<ErrorMessage name="fillingPressure" component="span" className="error-message" />
						</label>
						<label>
							Plnící médium
							<Field type="text" name="fillingMedium" style={{ width: "15rem" }} />
							<ErrorMessage name="fillingMedium" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Vytvořit"}
						</button>
					</Form>
				)}
			</Formik>
		</section>
	);
}
