import React from "react";
import { useSelector } from "react-redux";
import cs from "classnames";

import FillingTable from "pages/Root/components/Table/FillingTable";
import Loading from "components/Loading";
import FillingForm from "../../components/Form/FillingForm";

import useEditForm from "hooks/useEditForm";
import useRoles from "hooks/useRoles";

export default function Filling() {
	const { fillings } = useSelector(state => state.data);

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isTechnician } = useRoles();

	if (fillings === null) return <Loading />;

	return (
		<main>
			<h2>
				<picture>
					<source srcSet="/images/icons/filling_color.svg" />
					<img width="36px" src="/images/icons/filling_color.svg" alt="Ikona plnění" />
				</picture>
				Plnění
				<menu>
					{isTechnician ? (
						<button
							className={cs("action_button", { active: toggle })}
							onClick={() => setToggle(prevState => !prevState)}>
							{toggle ? "Zavřít formulář" : "Přidat plnění"}
						</button>
					) : null}
				</menu>
			</h2>
			{toggle && <FillingForm initialValues={fillings[editing]} id={editing} key={formKey} />}
			<FillingTable
				title="Seznam všech plnění"
				fillings={Object.values(fillings)}
				defaultPageSize={30}
				setEditing={setEditing}
			/>
		</main>
	);
}
