import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import CustomLink from "components/CustomLink";

import "./style.sass";

Navigation.propTypes = {
	darkBackground: PropTypes.bool,
};

/**
 * Component responsible for rendering the navigation menu.
 * @param {Object} props - Component props
 * @param {boolean} props.darkBackground - Indicates whether the background is dark
 * @returns {JSX.Element} - Rendered Navigation component
 */
export default function Navigation({ darkBackground = false }) {
	const location = useLocation(); // Get the current location from react-router-dom

	// Return the JSX for the Navigation component
	return (
		<ul className="component_navigation">
			<li>
				<CustomLink to="/predmety">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/item${location.pathname !== "/predmety" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/item${location.pathname !== "/predmety" ? "_color" : darkBackground ? "_white" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona předmětu"
						/>
					</picture>
					Předměty
				</CustomLink>
			</li>
			<li>
				<CustomLink to="/kompresory">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/compressor${location.pathname !== "/kompresory" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/compressor${location.pathname !== "/kompresory" ? "_color" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona komporesoru"
						/>
					</picture>
					Kompresory
				</CustomLink>
			</li>
			<li>
				<CustomLink to="/lahve">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/bottle${location.pathname !== "/lahve" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/bottle${location.pathname !== "/lahve" ? "_color" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona lahve"
						/>
					</picture>
					Láhve
				</CustomLink>
			</li>
			<li>
				<CustomLink to="/plneni">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/filling${location.pathname !== "/plneni" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/filling${location.pathname !== "/plneni" ? "_color" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona plnění"
						/>
					</picture>
					Plnění
				</CustomLink>
			</li>
			<li>
				<CustomLink to="/mista">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/place${location.pathname !== "/mista" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/place${location.pathname !== "/mista" ? "_color" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona místa"
						/>
					</picture>
					Místa
				</CustomLink>
			</li>
			<li>
				<CustomLink to="/jednotky">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/unit${location.pathname !== "/jednotky" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/unit${location.pathname !== "/jednotky" ? "_color" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona jednotky"
						/>
					</picture>
					Jednotky
				</CustomLink>
			</li>
			<li>
				<CustomLink to="/uzivatele">
					<picture>
						<source
							media="(prefers-color-scheme: dark)"
							srcSet={`/images/icons/user${location.pathname !== "/uzivatele" ? "_color" : "_white"}.svg`}
						/>
						<img
							width="24px"
							src={`/images/icons/user${location.pathname !== "/uzivatele" ? "_color" : darkBackground ? "_white" : ""}.svg`}
							alt="Ikona uživatele"
						/>
					</picture>
					Uživatelé
				</CustomLink>
			</li>
		</ul>
	);
}
