import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";

FillingTable.propTypes = {
	title: PropTypes.string,
	fillings: PropTypes.array,
	defaultPageSize: PropTypes.number,
	setEditing: PropTypes.func,
};

export default function FillingTable({
	title = "Seznam všech plnění",
	fillings = null,
	defaultPageSize = 30,
	setEditing,
}) {
	const { items, bottles, compressors, users } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (fillings === null) return;

		const newData = fillings.map(filling => {
			return {
				...filling,
				madeBy: { ...users?.[filling.madeBy], item: items?.[users?.[filling.madeBy]?.item] },
				filledBy: {
					...compressors?.[filling.filledBy],
					item: items?.[compressors?.[filling.filledBy]?.item],
				},
				bottle: { ...bottles?.[filling.bottle], item: items?.[bottles?.[filling.bottle]?.item] },
			};
		});
		setData({
			nodes: newData?.sort((a, b) =>
				moment(a.finish, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.finish, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			),
		});
	}, [fillings, items, bottles, compressors, users]);

	const columns = [
		{
			label: "Uživatel",
			search: filling => filling.madeBy?.username || filling.madeBy,
			renderCell: filling => (
				<Link to={`/uzivatele/${filling.madeBy?.id || ""}`}>{filling.madeBy?.username || "-"}</Link>
			),
			sort: { sortKey: "MADEBY" },
		},
		{
			label: "Kompresor",
			search: filling => filling.filledBy?.item?.label || "-",
			renderCell: filling => (
				<Link to={`/predmety/${filling.filledBy?.item?.id || ""}`}>
					{filling.filledBy?.item?.label || "-"}
				</Link>
			),
			sort: { sortKey: "FILLEDBY" },
		},
		{
			label: "Láhev",
			search: filling => filling.bottle?.item?.label || "-",
			renderCell: filling => (
				<Link to={`/predmety/${filling.bottle?.item?.id || ""}`}>
					{filling.bottle?.item?.label || "-"}
				</Link>
			),
			sort: { sortKey: "BOTTLE" },
		},
		{
			label: "Tlak [bar]",
			renderCell: filling => filling.pressure,
			sort: { sortKey: "PRESSURE" },
		},
		{
			label: "Začátek",
			renderCell: filling => (filling.start ? moment(filling.start).format("D. M. YYYY H:mm") : ""),
			sort: { sortKey: "START" },
		},
		{
			label: "Konec",
			renderCell: filling =>
				filling.finish ? moment(filling.finish).format("D. M. YYYY H:mm") : "",
			sort: { sortKey: "FINISH" },
		},
	];

	const sortFns = {
		MADEBY: array => array.sort((a, b) => a.madeBy.username.localeCompare(b.madeBy.username)),
		FILLEDBY: array =>
			array.sort((a, b) => a.filledBy.item.label.localeCompare(b.filledBy.item.label)),
		BOTTLE: array => array.sort((a, b) => a.bottle.item.label.localeCompare(b.bottle.item.label)),
		PRESSURE: array => array.sort((a, b) => a.pressure - b.pressure),
		START: array =>
			array.sort((a, b) =>
				moment(a.start, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.start, "YYYY-MM-DDTHH:mm:ssZ"))
					? -1
					: 1
			),
		FINISH: array =>
			array.sort((a, b) =>
				moment(a.finish, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.finish, "YYYY-MM-DDTHH:mm:ssZ"))
					? -1
					: 1
			),
	};

	if (fillings === null) return <Loading />;

	return (
		<CustomTable
			title={title}
			layout="minmax(7rem, 1fr) minmax(14rem, 1fr) minmax(16rem, 1fr) minmax(7rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr)"
			openUrl={filling => `/plneni/${filling.id}`}
			data={data}
			columns={columns}
			sortFns={sortFns}
			entity="fillings"
			actions={["edit", "delete"]}
			defaultPageSize={defaultPageSize}
			setEditing={setEditing}
		/>
	);
}
