import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import cs from "classnames";

import useNotifications from "hooks/useNotifications";
import Loading from "components/Loading";

import { login } from "../../../../../redux/settingsSlice";

import "./style.sass";

// Pop Form for login, even for current session expiration
export default function Login() {
	// Redux hooks for dispatching actions and accessing state
	const dispatch = useDispatch();
	const { credentials } = useSelector(state => state.settings);

	// React router hook for navigate function so window location can be easily updated
	const navigate = useNavigate();

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("login");

	// Define validation schema for form fields
	const validationSchema = Yup.object().shape({
		username: Yup.string()
			.required("Povinné pole")
			.matches(/^[a-zA-Z0-9.]*$/, "Nevadiní přihlašovací jméno, povolené znaky a-z, A-Z, 0-9 '.'.")
			.min(2, "Přihlašovací jméno musí obsahovat alespoň dva znaky."),
		password: Yup.string().required("Povinné pole"),
	});

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);
	useEffect(() => {
		setFormKey(prevState => prevState + 1);
	}, [credentials?.username]);

	// State to manage form submission status
	const [isSubmiting, setSubmiting] = useState(false);

	// Effect to reset submission status when notifications change
	// Happens only after the server response to login request, since the notifications subscribe just these types of notifications
	useEffect(() => setSubmiting(false), [notifications.length]);

	return (
		<div id="page_root_component_login" className="pop_form">
			<section>
				<header>
					<h1>Přihlášení</h1>
					{notifications.length ? <span>{notifications[0]?.message}</span> : null}
				</header>
				{/* Render loading component if submitting, else render form */}
				{isSubmiting ? (
					<Loading showText={false} />
				) : (
					<Formik
						key={formKey}
						initialValues={{ username: credentials?.username, password: "" }}
						validationSchema={validationSchema}
						onSubmit={values => {
							// Dispatch login action
							dispatch(login(values));

							// Navigate function updates browser history, so password managers can promp to save the filled credentials
							navigate(window.location);

							// Increment form key to trigger re-render
							setFormKey(prevState => prevState + 1);
							setSubmiting(true);
						}}>
						{({ values }) => (
							<Form className={cs({ disabled: isSubmiting })}>
								<label>
									Přihlašovací jméno
									<Field type="text" name="username" value={values.username} />
									<ErrorMessage name="username" component="span" className="error-message" />
								</label>
								<label>
									Heslo
									<Field type="password" name="password" value={values.password} />
									<ErrorMessage name="password" component="span" className="error-message" />
								</label>
								<button className="action_button" type="submit">
									Přihlásit
								</button>
							</Form>
						)}
					</Formik>
				)}
			</section>
		</div>
	);
}
