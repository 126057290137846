import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cs from "classnames";

import { filterProperties } from "pages/Root/helpers";
import useNotifications from "hooks/useNotifications";
import { updateObject } from "../../../../../../../redux/dataSlice";

AdminPasswordForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	subtitle: PropTypes.bool,
};

export default function AdminPasswordForm({ initialValues }) {
	const defaultValues = {
		username: "Admin",
		plainPassword: "",
	};
	const mergedDefaults = { ...defaultValues, ...initialValues };

	const dispatch = useDispatch();
	const session = useSelector(state => state.settings.session);

	const validationSchema = Yup.object().shape({
		plainPassword: Yup.string().required("Povinné pole"),
	});

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("users");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	return (
		<section className="page_root_component_init_admin_password">
			<h3>Nastavit heslo pro Admina</h3>
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					dispatch(
						updateObject({
							entity: "users",
							id: session["@id"],
							values,
						})
					);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting }) => (
					<Form className="form withoutHeader">
						<label>
							Heslo
							<Field type="password" name="plainPassword" />
							<ErrorMessage name="plainPassword" component="span" className="error-message" />
						</label>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							Nastavit heslo
						</button>
					</Form>
				)}
			</Formik>
		</section>
	);
}
