import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import cs from "classnames";

import Loading from "components/Loading";
import { translateType } from "pages/Root/helpers";
import ItemForm from "pages/Root/components/Form/ItemForm";
import CompressorDetail from "../../../components/Detail/CompressorDetail";
import BottleDetail from "../../../components/Detail/BottleDetail";
import RevisionDetail from "../../../components/Detail/RevisionDetail";
import useEditForm from "hooks/useEditForm";
import UsageDetail from "../../../components/Detail/UsageDetail";
import PlacementDetail from "../../../components/Detail/PlacementDetail";
import useRoles from "hooks/useRoles";

export default function ItemDetail() {
	let { itemId } = useParams();
	const navigate = useNavigate();

	const { items, units, revisions, placements, usages, places } = useSelector(state => state.data);

	const [item, setItem] = useState(null);
	useEffect(() => setItem(items?.[`/api/items/${itemId}`]), [items, itemId]);
	const IS_COMPRESSOR = item?.type === "COMPRESSOR";
	const IS_BOTTLE = item?.type === "BOTTLE";

	useEffect(() => {
		const newestPlacement = Object.values(placements || {})
			.filter(placement => placement.items.find(url => url === `/api/items/${itemId}`))
			.sort((a, b) =>
				moment(a.date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.date, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			)[0];
		setItem(prevState => {
			return {
				...prevState,
				located: places?.[newestPlacement?.located],
				belongsTo: typeof item?.belongsTo === "string" ? units?.[item?.belongsTo] : item?.belongsTo,
			};
		});
	}, [placements, places, units]);

	const emplacedRevisions = useMemo(() => {
		return Object.values(revisions || {})
			?.filter(revision => revision.items.find(url => url === item?.["@id"]))
			.sort((a, b) =>
				moment(a.next, "YYYY-MM-DDTHH:mm:ssZ").isBefore(moment(b.next, "YYYY-MM-DDTHH:mm:ssZ"))
					? 1
					: -1
			);
	}, [item?.revisions, revisions]);

	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isAdmin } = useRoles();

	if (Object.values(items || {}).length === 0) return <Loading />;

	if (Object.values(items || {}).length && item?.["@id"] === undefined) {
		return (
			<main className="empty_message">
				<h1>Tento předmět nebyl nalezen.</h1>
			</main>
		);
	}

	return (
		<main>
			<header>
				<menu>
					<button className="link" onClick={() => navigate(-1)}>
						⇦ Zpět
					</button>
				</menu>
				<h2>
					Předmět {item?.label || "-"}
					<nav>
						<a href="#revize">Revize</a>
						<a href="#pouziti">Použití</a>
						<a href="#umisteni">Umístění</a>
					</nav>
				</h2>
			</header>
			<section>
				<h3>
					Základní informace
					<menu>
						{isAdmin ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => {
									setToggle(prevState => !prevState);
									setEditing(item["@id"]);
								}}>
								{toggle ? "Zavřít formulář" : "Upravit"}
							</button>
						) : null}
					</menu>
				</h3>
				{toggle && (
					<ItemForm initialValues={items[editing]} id={editing} key={formKey} subtitle={true} />
				)}
				<dl className="description_list">
					<div>
						<dt>Typ</dt>
						<dd>{translateType(item.type)}</dd>
					</div>
					<div>
						<dt>Sériové číslo</dt>
						<dd>{item.serialNumber || "-"}</dd>
					</div>
					<div>
						<dt>Označení</dt>
						<dd>{item.label || "-"}</dd>
					</div>
					<div>
						<dt>Výrobce</dt>
						<dd>{item.manufacturer || "-"}</dd>
					</div>
					<div>
						<dt>Popis</dt>
						<dd>{item.description || "-"}</dd>
					</div>
					<div>
						<dt>Zavedení do provozu</dt>
						<dd>{item.commissioning ? moment(item.commissioning).format("D. M. YYYY") : "-"}</dd>
					</div>
					<div>
						<dt>Datum výroby</dt>
						<dd>
							{item.dateOfManufacture ? moment(item.dateOfManufacture).format("D. M. YYYY") : "-"}
						</dd>
					</div>
					<div>
						<dt>Stav</dt>
						<dd>{item.state ? "použitelný" : "nepoužitelný"}</dd>
					</div>
					<div>
						<dt>Jednotka</dt>
						<dd>
							{item.belongsTo?.name ? (
								<Link to={`/jednotky?hledat=${item.belongsTo?.name}`}>
									{item.belongsTo?.name || item.belongsTo}
								</Link>
							) : (
								"-"
							)}
						</dd>
					</div>
				</dl>
				<h3>Provozní informace</h3>
				<dl className="description_list">
					<div>
						<dt>Poslední revize</dt>
						<dd>
							{emplacedRevisions?.[0]?.date
								? moment(emplacedRevisions?.[0]?.date).format("D. M. YYYY")
								: "Není vyplněna"}
						</dd>
					</div>
					<div>
						<dt>Následující revize</dt>
						<dd className={cs({ red: moment(emplacedRevisions?.[0]?.next).isBefore(moment()) })}>
							{emplacedRevisions?.[0]?.next
								? moment(emplacedRevisions?.[0]?.next).format("D. M. YYYY")
								: "Není vyplněna"}
						</dd>
					</div>
					<div>
						<dt>Poslední místo</dt>
						<dd>
							{item.located?.name ? (
								<Link to={`/mista?hledat=${item.located.name || ""}`}>{item.located.name}</Link>
							) : (
								"-"
							)}
						</dd>
					</div>
				</dl>
			</section>
			{IS_COMPRESSOR && <CompressorDetail item={item} />}
			{IS_BOTTLE && <BottleDetail item={item} />}
			<RevisionDetail
				item={item}
				revisions={
					revisions &&
					Object.values(revisions).filter(revision => revision.items.find(id => id === item["@id"]))
				}
				about="Zde jsou informace o všech revizích tohoto předmětu."
			/>
			<UsageDetail
				item={item}
				usages={
					usages &&
					Object.values(usages).filter(usage => usage.items.find(id => id === item["@id"]))
				}
				about="Zde jsou informace o všech použití tohoto předmětu."
			/>
			<PlacementDetail
				item={item}
				placements={
					placements &&
					Object.values(placements).filter(placement =>
						placement.items.find(id => id === item["@id"])
					)
				}
				about="Zde jsou informace o všech umístění tohoto předmětu."
			/>
		</main>
	);
}
