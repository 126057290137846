import React, { useState } from "react";
import cs from "classnames";

import Navigation from "components/Navigation";

import "./style.sass";

/**
 * Aside component represents a side navigation menu for all subpages offered by the application.
 *
 * @returns {JSX.Element} Aside navigation menu with a list of subpages.
 */
export default function Aside() {
	// State to control the visibility of the aside menu
	const [open, setOpen] = useState(false);

	return (
		<aside
			id="page_root_component_aside"
			onClick={() => setOpen(prevState => !prevState)}
			className={cs({ open: open })}>
			<h2>Stránky</h2>
			<Navigation />
		</aside>
	);
}
