import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import CustomLink from "components/CustomLink";

import { logout, setSession } from "../../../../redux/settingsSlice";

import "./style.sass";

/**
 * Header component represents the top header section of the application.
 * It includes the application title, user session information, and logout button.
 *
 * @returns {JSX.Element} Header section of the application.
 */
export default function Header() {
	// Redux state selectors
	const credentials = useSelector(state => state.settings.credentials);
	const session = useSelector(state => state.settings.session);
	const { users } = useSelector(state => state.data);
	const dispatch = useDispatch();

	// useEffect hook to set the user session when credentials are available
	useEffect(() => {
		if (!credentials?.token) return;
		else dispatch(setSession({ username: credentials?.username, users }));
	}, [users, credentials, credentials?.token, dispatch]);

	return (
		<header id="page_root_component_header">
			{/* Application title */}
			<CustomLink to="/" id="page_root_component_header_title">
				<h1>Webový provozní deník kompresoru</h1>
			</CustomLink>
			{/* User session information */}
			{session && (
				<CustomLink to={`/uzivatele/${session.id}`} id="page_root_component_header_session">
					<picture className={session?.roles[0]}>
						<source srcSet={`/images/avatars/${session?.roles[0]}.png`} />
						<img
							width="26px"
							height="26px"
							src={`/images/avatars/${session?.roles[0]}.png`}
							alt={`ikona role ${session?.roles[0]}`}
						/>
					</picture>
					{session?.username || "uživatel"}
				</CustomLink>
			)}
			{/* Logout button */}
			<button className="action_button red" onClick={() => dispatch(logout())}>
				Odhlásit
			</button>
		</header>
	);
}
