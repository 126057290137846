import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * Custom hook for managing an edit form's state.
 * @returns {Object} An object containing state variables and functions to control the form.
 */
export default function useEditForm() {
	// State for toggling the form visibility
	const [toggle, setToggle] = useState(false);
	// State for tracking the currently edited item
	const [editing, setEditing] = useState(null);
	// State for managing form key to force re-rendering of the form component
	const [formKey, setFormKey] = useState(0);

	// Effect to reset the editing state and generate a new form key when the form is toggled off
	useEffect(() => {
		if (toggle === false) {
			setEditing(null);
			setFormKey(uuidv4());
		}
	}, [toggle]);

	// Effect to toggle the form and generate a new form key when an item is selected for editing
	useEffect(() => {
		if (editing !== null) {
			setToggle(true);
			setFormKey(uuidv4());
		}
	}, [editing]);

	// Effect to scroll the form into view on small screens when it's toggled or an item is being edited
	useEffect(() => {
		if (toggle || editing) {
			// Scroll the form into view if it exists and the screen is small
			const formElement = document.querySelector(".form");
			if (formElement && window.matchMedia("(max-width: 768px)").matches) {
				const section = formElement.closest("section");
				if (section) section.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [toggle, editing]);

	// Return the state variables and functions to control the form
	return { toggle, setToggle, editing, setEditing, formKey };
}
