import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Loading from "components/Loading";

import FillingTable from "pages/Root/components/Table/FillingTable";
import useEditForm from "hooks/useEditForm";
import FillingForm from "pages/Root/components/Form/FillingForm";
import useRoles from "hooks/useRoles";

FillingDetail.propTypes = {
	fillings: PropTypes.array,
	about: PropTypes.string,
	item: PropTypes.object,
	user: PropTypes.object,
};

export default function FillingDetail({ fillings, about, user, item }) {
	const { toggle, setToggle, editing, setEditing, formKey } = useEditForm();

	const { isTechnician } = useRoles();

	if (!fillings) return <Loading />;

	return (
		<React.Fragment>
			<section id="page_fillings" className="noBreak">
				<h3>
					Plnění
					<menu>
						{isTechnician ? (
							<button
								className={cs("action_button", { active: toggle })}
								onClick={() => setToggle(prevState => !prevState)}>
								{toggle ? "Zavřít formulář" : "Přidat plnění"}
							</button>
						) : null}
					</menu>
				</h3>
				{about ? <p>{about}</p> : null}
				{toggle && (
					<FillingForm
						initialValues={fillings.find(filling => filling["@id"] === editing)}
						id={editing}
						key={formKey}
						item={item}
						user={user}
						subtitle={true}
					/>
				)}
			</section>
			<FillingTable
				title="Seznam provedených plnění"
				fillings={fillings}
				defaultPageSize={10}
				setEditing={setEditing}
			/>
		</React.Fragment>
	);
}
