import React, { useState, useMemo } from "react";
import { TablePagination } from "@mui/material";
import { CompactTable } from "@table-library/react-table-library/compact";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import PropTypes from "prop-types";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteObject } from "../../redux/dataSlice";
import Loading from "components/Loading";

import "./style.sass";

CustomTable.propTypes = {
	title: PropTypes.string,
	about: PropTypes.string,
	layout: PropTypes.string,
	openUrl: PropTypes.func,
	deleteUrl: PropTypes.func,
	data: PropTypes.object,
	columns: PropTypes.array,
	sortFns: PropTypes.object,
	select: PropTypes.object,
	entity: PropTypes.string,
	actions: PropTypes.array,
	actionDefinitions: PropTypes.object,
	defaultPageSize: PropTypes.number,
	setEditing: PropTypes.func,
};

/**
 * Component for rendering a custom table with features like sorting, pagination, and actions.
 * @param {Object} props - Component props
 * @param {string} [props.title] - Title of the table
 * @param {string} [props.about] - Information about the table
 * @param {string} [props.layout] - Layout of the table
 * @param {Function} props.openUrl - Function to generate URL for opening items
 * @param {Function} [props.deleteUrl=item => item["@id"]] - Function to generate URL for deleting items
 * @param {Object} props.data - Data for the table
 * @param {Array} props.columns - Columns configuration for the table
 * @param {Object} props.sortFns - Sort functions for the table
 * @param {Object} [props.select] - Select configuration for the table
 * @param {string} [props.entity] - Entity type for the table
 * @param {Array} [props.actions=[]] - Actions to be displayed in the table
 * @param {Object} [props.actionDefinitions={}] - Definitions for actions in the table
 * @param {number} [props.defaultPageSize=30] - Default page size for pagination
 * @param {Function} props.setEditing - Function to set editing mode
 * @returns {JSX.Element} - Rendered CustomTable component
 */
export default function CustomTable({
	title,
	about,
	layout = undefined,
	openUrl,
	deleteUrl = item => item["@id"],
	data,
	columns,
	sortFns,
	select = undefined,
	entity,
	actions = [],
	actionDefinitions = {},
	defaultPageSize = 30,
	setEditing,
}) {
	// Get search parameter from URL, which is then used for filtering
	const [searchParams] = useSearchParams();
	const filter = searchParams.get("hledat");

	// State for search input value
	const [search, setSearch] = React.useState(filter || "");
	const handleSearch = event => {
		setSearch(event.target.value);
	};

	// Get session data from Redux store
	const session = useSelector(state => state.settings.session);

	// Filter actions based on user roles
	actions = actions.filter(action => {
		switch (action) {
			case "delete":
			case "lock":
				return session?.roles.includes("ROLE_ADMIN");
			case "edit":
				if (session?.roles.includes("ROLE_ADMIN")) return true;
				else if (
					(entity === "revisions" || entity === "fillings") &&
					session?.roles.includes("ROLE_TECHNICIAN")
				)
					return true;
				else if (entity === "placements" && session?.roles.includes("ROLE_MANIPULATOR"))
					return true;
				return false;
			default:
				return true;
		}
	});

	// Generate column widths based on layout and actions
	const columnWidths = useMemo(() => {
		const defaultColumnWidth = "minmax(12rem, 1fr)";
		const actionColumnWidth = "2.5rem";
		const openActionWidth = "5.5rem";

		const defaultLayout =
			layout || Array.from({ length: columns.length }, () => defaultColumnWidth).join(" ");

		// -1 for the openActionWidth, which renders also 2.5rem if not present
		const actionWidths = Array.from({ length: actions.length - 1 }, () => actionColumnWidth).join(
			" "
		);
		const openActionWidths = actions.includes("open") ? openActionWidth : actionColumnWidth;

		return [defaultLayout, actionWidths, openActionWidths].join(" ");
	}, [layout, columns, actions]);

	// Theme configuration for the table
	const theme = useTheme({
		HeaderRow: `grid-template-columns: ${columnWidths};`,
		Row: `grid-template-columns: ${columnWidths};`,
		Cell: `> div { overflow: visible; }`,
	});

	const dispatch = useDispatch();

	// Function to handle item deletion
	const handleDelete = id => {
		const confirmation = window.confirm("Opravdu si přejete záznam smazat?");

		if (confirmation) dispatch(deleteObject(id));
	};

	// Default action definitions for the table
	const defaultActionDefinitions = {
		open: {
			label: "",
			renderCell: item => (
				<Link to={openUrl(item)} className="action_button">
					Otevřít
				</Link>
			),
		},
		edit: {
			label: "",
			renderCell: item => (
				<span className="link" onClick={() => setEditing(item["@id"])}>
					<picture>
						<source media="(prefers-color-scheme: dark)" srcSet={`/images/ui/edit_white.svg`} />
						<img width="24px" src={`/images/ui/edit.svg`} alt="Ikona úpravy záznamu" />
					</picture>
				</span>
			),
		},
		delete: {
			label: "",
			renderCell: item => (
				<span className="link" onClick={() => handleDelete(deleteUrl(item))}>
					<picture>
						<source media="(prefers-color-scheme: dark)" srcSet={`/images/ui/delete_white.svg`} />
						<img width="24px" src={`/images/ui/delete.svg`} alt="Ikona smazání" />
					</picture>
				</span>
			),
		},
	};

	// Merge default and custom action definitions
	actionDefinitions = { ...actionDefinitions, ...defaultActionDefinitions };

	// Add action columns to the columns configuration
	actions.forEach(action => (columns = [...columns, actionDefinitions[action]]));

	// Pagination hooks
	const pagination = usePagination(data.nodes, {
		state: {
			page: 0,
			size: defaultPageSize,
		},
	});

	// State for selected column index
	const [selectedColumn, setSelectedColumn] = useState(0);
	const handleSelectChange = event => setSelectedColumn(parseInt(event.target.value, 10));

	// Filter nodes based on search input
	// Property .search does help if function .renderCell returns entire Element
	const nodes = data.nodes.filter(item => {
		const getValue = columns[selectedColumn].search || columns[selectedColumn].renderCell;
		return (getValue(item) || "").toString().toLowerCase().includes(search.toLowerCase());
	});

	// Sorting hooks
	const sort = useSort(
		data.nodes,
		{},
		{
			sortFns,
			sortIcon: {
				iconDefault: (
					<picture>
						<source media="(prefers-color-scheme: dark)" srcSet="/images/ui/unsorted_white.svg" />
						<img width="16px" src="/images/ui/unsorted.svg" alt="Ikona šipky nahoru i dolů" />
					</picture>
				),
				iconUp: (
					<picture>
						<source media="(prefers-color-scheme: dark)" srcSet="/images/ui/arrow_up_white.svg" />
						<img width="16px" src="/images/ui/arrow_up.svg" alt="Ikona šipky nahoru" />
					</picture>
				),
				iconDown: (
					<picture>
						<source media="(prefers-color-scheme: dark)" srcSet="/images/ui/arrow_down_white.svg" />
						<img width="16px" src="/images/ui/arrow_down.svg" alt="Ikona šipky dolů" />
					</picture>
				),
			},
		}
	);

	// Render loading indicator if session data is null
	if (session === null) return <Loading />;

	// Render table component
	return (
		<section className="component_customtable">
			<h3>{title}</h3>
			{about ? <p>{about}</p> : null}
			<label>
				<picture>
					<source media="(prefers-color-scheme: dark)" srcSet="/images/icons/search_white.svg" />
					<img width="28px" src="/images/icons/search.svg" alt="Ikona šipky dolů" />
				</picture>
				<span>
					Hledat podle sloupce
					<select value={selectedColumn} onChange={handleSelectChange}>
						{columns
							.filter(column => column.label)
							.map((column, key) => (
								<option key={key} value={key}>
									{column.label}
								</option>
							))}
					</select>
				</span>
				<input type="text" value={search} onChange={handleSearch} />
			</label>
			{nodes.length ? (
				<React.Fragment>
					<CompactTable
						columns={columns}
						data={{ nodes }}
						sort={sort}
						pagination={pagination}
						select={select}
						layout={{ custom: true }}
						theme={theme}
					/>
					{nodes.length > defaultPageSize && (
						<table className="table_pagination">
							<tbody>
								<tr>
									<TablePagination
										count={nodes.length}
										page={pagination.state.page}
										rowsPerPage={pagination.state.size}
										rowsPerPageOptions={[10, 30, 60]}
										onRowsPerPageChange={event =>
											pagination.fns.onSetSize(parseInt(event.target.value, 10))
										}
										onPageChange={(event, page) => pagination.fns.onSetPage(page)}
										labelRowsPerPage="Počet řádků na stránku"
										labelDisplayedRows={({ from, to, count }) => {
											return "" + from + "-" + to + " z " + count;
										}}
									/>
								</tr>
							</tbody>
						</table>
					)}
				</React.Fragment>
			) : (
				<span>Žádné výsledky nebyly nalezeny</span>
			)}
		</section>
	);
}
