import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";

import reportWebVitals from "reportWebVitals";
import store from "store";

import Error from "pages/Error";
import Root from "pages/Root";
import Index from "pages/Root/subpages/Index";
import Item from "pages/Root/subpages/Item";
import ItemDetail from "pages/Root/subpages/Item/subpages";
import Bottle from "pages/Root/subpages/Bottle";
import Compressor from "pages/Root/subpages/Compressor";
import Filling from "pages/Root/subpages/Filling";
import Place from "pages/Root/subpages/Place";
import Unit from "pages/Root/subpages/Unit";
import User from "pages/Root/subpages/User";
import UserDetail from "pages/Root/subpages/User/subpages";

import "assets/styles/styles.sass";

// Create a browser router with routes configuration
const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <Error />,
		children: [
			{
				path: "",
				element: <Index />,
			},
			{
				path: "lahve",
				element: <Bottle />,
			},
			{
				path: "kompresory",
				element: <Compressor />,
			},
			{
				path: "plneni",
				element: <Filling />,
			},
			{
				path: "predmety",
				element: <Item />,
			},
			{
				path: "predmety/:itemId",
				element: <ItemDetail />,
			},
			{
				path: "mista",
				element: <Place />,
			},
			{
				path: "jednotky",
				element: <Unit />,
			},
			{
				path: "uzivatele",
				element: <User />,
			},
			{
				path: "uzivatele/:userId",
				element: <UserDetail />,
			},
		],
	},
]);

// Create a root for ReactDOM rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
// Render the main application within the React StrictMode and with Redux store provider
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<RouterProvider router={router} />
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
