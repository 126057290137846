import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import cs from "classnames";

import { createObject, updateObject } from "../../../../../redux/dataSlice";
import { filterProperties } from "pages/Root/helpers";
import useNotifications from "hooks/useNotifications";
import CustomSelect from "components/CustomSelect";
import { translateType } from "pages/Root/helpers";

ItemForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
	subtitle: PropTypes.bool,
};

export default function ItemForm({ initialValues, id, subtitle = false }) {
	let defaultValues = {
		serialNumber: "",
		label: "",
		description: "",
		manufacturer: "",
		commissioning: "",
		dateOfManufacture: "",
		state: false,
		belongsTo: "",
	};
	if (!id) defaultValues.type = "";

	// Convert initial dates to Moment objects
	const defaultCommissioning = initialValues?.commissioning
		? moment(initialValues.commissioning).format("YYYY-MM-DD")
		: "";
	const defaultDateOfManufacture = initialValues?.dateOfManufacture
		? moment(initialValues.dateOfManufacture).format("YYYY-MM-DD")
		: "";

	const mergedDefaults = {
		...defaultValues,
		...initialValues,
		commissioning: defaultCommissioning,
		dateOfManufacture: defaultDateOfManufacture,
	};

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		type: id ? Yup.string().notRequired() : Yup.string().required("Typ je povinný"),
		serialNumber: Yup.string(),
		label: Yup.string(),
		description: Yup.string(),
		manufacturer: Yup.string(),
		dateOfManufacture: Yup.date(),
		commissioning: Yup.date().when("dateOfManufacture", {
			is: date => date && date !== "",
			then: () => Yup.date().min(Yup.ref("dateOfManufacture"), "Datum musí být po datu revize"),
		}),
		state: Yup.boolean().required("Stav je povinný"),
		belongsTo: Yup.string(),
	});

	const { units, items } = useSelector(state => state.data);
	const unitOptions = useMemo(() => {
		return Object.entries(units || {})?.map(([id, unit]) => ({
			label: unit.name,
			value: id,
		}));
	}, [units]);

	const typeOptions = useMemo(() => {
		return Object.values(items || {}).reduce(
			(options, item) => {
				return !options.find(option => option.value === item.type)
					? [...options, { label: translateType(item.type), value: item.type }]
					: options;
			},
			[
				{ label: translateType("COMPRESSOR"), value: "COMPRESSOR" },
				{ label: translateType("BOTTLE"), value: "BOTTLE" },
				{ label: translateType("MASK"), value: "MASK" },
			]
		);
	}, [items]);

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("items");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	return (
		<section>
			{subtitle ? (
				<h4>
					{id ? "Upravit" : "Vytvořit"} předmět {mergedDefaults.label}
				</h4>
			) : (
				<h3>
					{id ? "Upravit" : "Vytvořit"} předmět {mergedDefaults.label}
				</h3>
			)}

			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					const formattedValues = {
						...values,
						commissioning: moment(values.commissioning).toISOString(),
						dateOfManufacture: moment(values.dateOfManufacture).toISOString(),
					};
					dispatch(
						id
							? updateObject({ entity: "items", id, values: formattedValues })
							: createObject({ entity: "items", values: formattedValues })
					);
					setSubmitting(false);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className="form">
						{!id && (
							<label>
								Typ
								<Field
									name="type"
									component={CustomSelect}
									placeholder="Vyberte typ..."
									defaultOptions={typeOptions}
									isMulti={false}
									isClearable={true}
								/>
								<ErrorMessage name="type" component="span" className="error-message" />
							</label>
						)}
						<label>
							Sériové číslo
							<Field type="text" name="serialNumber" style={{ width: "5rem" }} />
							<ErrorMessage name="serialNumber" component="span" className="error-message" />
						</label>
						<label>
							Označení
							<Field type="text" name="label" />
							<ErrorMessage name="label" component="span" className="error-message" />
						</label>
						<label>
							Popis
							<Field type="text" name="description" style={{ width: "15rem" }} />
							<ErrorMessage name="description" component="span" className="error-message" />
						</label>
						<label>
							Výrobce
							<Field type="text" name="manufacturer" style={{ width: "8rem" }} />
							<ErrorMessage name="manufacturer" component="span" className="error-message" />
						</label>
						<label>
							Zavedení
							<Field type="date" name="commissioning" />
							<ErrorMessage name="commissioning" component="span" className="error-message" />
						</label>
						<label>
							Datum výroby
							<Field type="date" name="dateOfManufacture" />
							<ErrorMessage name="dateOfManufacture" component="span" className="error-message" />
						</label>
						<label>
							Stav
							<Field type="checkbox" name="state" />
							<ErrorMessage name="state" component="span" className="error-message" />
						</label>
						<label>
							Jednotka
							<Field
								name="belongsTo"
								component={CustomSelect}
								placeholder="Vyberte jednotku..."
								defaultOptions={unitOptions}
								isMulti={false}
							/>
							<ErrorMessage name="belongsTo" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Vytvořit"}
						</button>
					</Form>
				)}
			</Formik>
		</section>
	);
}
