import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import cs from "classnames";

import Loading from "components/Loading";
import CustomTable from "components/CustomTable";

NotFilledBottleTable.propTypes = {
	title: PropTypes.string,
	about: PropTypes.string,
	defaultPageSize: PropTypes.number,
};

export default function NotFilledBottleTable({ title, about, defaultPageSize = 30 }) {
	const { bottles, fillings, items } = useSelector(state => state.data);

	const [data, setData] = useState({ nodes: [] });
	useEffect(() => {
		if (fillings === null || bottles === null) return;

		const newData = Object.values(bottles || {}).map(bottle => {
			const bottleFilings = Object.values(fillings || {})
				.filter(filling => filling.bottle === bottle["@id"])
				.sort((a, b) =>
					moment(a.finish, "YYYY-MM-DDTHH:mm:ssZ").isBefore(
						moment(b.finish, "YYYY-MM-DDTHH:mm:ssZ")
					)
						? 1
						: -1
				);
			return {
				bottle: {
					...bottle,
					item: items?.[bottle?.item],
				},
				filling: bottleFilings?.[0],
			};
		});

		setData({
			nodes: newData?.sort((a, b) => {
				if (a.filling?.finish === undefined) return 1;
				if (b.filling?.finish === undefined) return -1;

				return moment(a.filling?.finish, "YYYY-MM-DDTHH:mm:ssZ").isBefore(
					moment(b.filling?.finish, "YYYY-MM-DDTHH:mm:ssZ")
				)
					? -1
					: 1;
			}),
		});
	}, [fillings, bottles, items]);

	const columns = [
		{
			label: "Láhev",
			search: row => row.bottle?.item?.label || "-",
			renderCell: row => (
				<Link to={`/predmety/${row.bottle?.item?.id || ""}`}>{row.bottle?.item?.label || "-"}</Link>
			),
			sort: { sortKey: "BOTTLE" },
		},
		{
			label: "Poslední plnění",
			search: row =>
				row.filling?.finish ? moment(row.filling?.finish).format("D. M. YYYY H:mm") : "-",
			renderCell: row =>
				row.filling?.finish ? (
					<span
						className={cs({
							red: moment(row.filling?.finish).isBefore(moment().subtract(6, "months")),
						})}>
						{moment(row.filling?.finish).format("D. M. YYYY H:mm")}
					</span>
				) : (
					"-"
				),
			sort: { sortKey: "FINISH" },
		},
	];

	const sortFns = {
		BOTTLE: array =>
			array.sort((a, b) => a.bottle.item?.label?.localeCompare(b.bottle.item?.label)),
		FINISH: array =>
			array.sort((a, b) =>
				moment(a.filling.finish, "YYYY-MM-DDTHH:mm:ssZ").isBefore(
					moment(b.filling.finish, "YYYY-MM-DDTHH:mm:ssZ")
				)
					? -1
					: 1
			),
	};

	if (fillings === null) return <Loading />;

	return (
		<CustomTable
			title={title}
			about={about}
			layout="minmax(14rem, 1fr) minmax(14rem, 1fr)"
			data={data}
			columns={columns}
			sortFns={sortFns}
			defaultPageSize={defaultPageSize}
		/>
	);
}
