import React from "react";
import PropTypes from "prop-types";
import "./style.sass";

Loading.propTypes = {
	showText: PropTypes.bool, // Boolean flag to indicate whether to show text
};

/**
 * Component responsible for displaying a loading animation.
 * @param {Object} props - Component props
 * @param {boolean} props.showText - Text to display or a boolean flag to indicate whether to display text
 * @returns {JSX.Element} - Rendered Loading component
 */
export default function Loading({ showText = true }) {
	return (
		<div className="component_loading">
			<img src="/images/icons/loading.svg" alt="Loading icon" />
			{showText ? <span>Načítání</span> : null}
		</div>
	);
}
