import React from "react";
import { Link, useRouteError } from "react-router-dom";

import "./style.sass";

/**
 * Error component displays an error message when an error occurs in the application.
 * It retrieves the error information using the useRouteError hook from react-router-dom.
 *
 * @returns {JSX.Element} Error message and a link to the home page.
 */
export default function Error() {
	// Retrieve the error information using useRouteError hook
	const error = useRouteError();

	return (
		<main id="page_error">
			<h1>Oops!</h1>
			<p>Omlouváme se, nastala chyba v aplikaci.</p>
			<p>
				<i>{error.statusText || error.message}</i>
			</p>
			<Link to="/">Domovská stránka</Link>
		</main>
	);
}
