import { createSlice } from "@reduxjs/toolkit";

import client from "client"; // Importing the client function for making HTTP requests

// Async action to handle user login
export const login = values => async dispatch => {
	try {
		// Send a POST request to the login endpoint with provided credentials
		const response = await client("/api/login", "post", values);
		if (response.data) {
			// If login successful, set user credentials in the state
			dispatch(setCredentials({ ...response.data, username: values.username }));
			// Dispatch a notification of successful login
			dispatch(notify({ message: "Úspěšné přihlášení!", type: "success" }));

			// If default password is used, open initialization form for modification
			dispatch(setInitForm(values.username === "Admin" && values.password === "Admin" ? 0 : -1));
		}
	} catch (error) {
		// If login fails, dispatch an error notification and set the username in the state
		dispatch(setUsername(values.username));
		dispatch(notify({ message: "Špatné přihlašovací údaje!", type: "error", category: "login" }));
	}
};

// Function to clear user session and credentials
function empty(state) {
	state.session = null;
	const credentials = { username: state.credentials?.username };
	state.credentials = credentials;
	localStorage.setItem("credentials", JSON.stringify(credentials));
}

// Define the settings slice
const settingsSlice = createSlice({
	name: "settings",
	initialState: {
		session: null,
		credentials: JSON.parse(localStorage.getItem("credentials")), // Load credentials from local storage
		notifications: [], // Array to store notifications
		initForm: -1, // State for initialization form
	},
	reducers: {
		// Reducer to set the username in the state
		setUsername: (state, action) => {
			state.credentials = { ...state.credentials, username: action.payload };
		},
		// Reducer to set user credentials in the state and local storage
		setCredentials: (state, action) => {
			const credentials = { ...action.payload, expiration: new Date().getTime() + 3600 * 1000 };
			state.credentials = credentials;
			localStorage.setItem("credentials", JSON.stringify(credentials));
		},
		// Reducer to handle user logout
		logout: state => {
			empty(state); // Clear session and credentials
			// Add a success notification for logout
			state.notifications = [
				{ message: "Úspěšné odhlášení!", type: "success" },
				...state.notifications,
			];
		},
		// Reducer to mark a notification as read
		read: state => {
			state.notifications = state.notifications.slice(0, -1); // Remove the last notification
		},
		// Reducer to add a notification to the state
		notify: (state, action) => {
			state.notifications = [action.payload, ...state.notifications]; // Add new notification to the array
		},
		// Reducer to handle unauthorized access
		unauthorized: state => {
			if (state.credentials?.token !== undefined)
				// If token exists in credentials
				state.notifications = [
					{ message: "Nejste přihláše/a!", type: "error" }, // Add an error notification
					...state.notifications,
				];

			empty(state); // Clear session and credentials
		},
		// Reducer to set the state for the initialization form
		setInitForm: (state, action) => {
			state.initForm = action.payload;
		},
		// Reducer to set the user session in the state
		setSession: (state, action) => {
			const { users, username } = action.payload;
			if (users === null) return; // If users data is null, return
			state.session = Object.values(users).find(user => user.username === username); // Set session data
		},
	},
});

// Extract action creators and reducer from settings slice
export const {
	logout,
	read,
	notify,
	unauthorized,
	setCredentials,
	setSession,
	setUsername,
	setInitForm,
} = settingsSlice.actions;

export default settingsSlice.reducer; // Export the reducer
