import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import cs from "classnames";

import { createObject, updateObject } from "../../../../../redux/dataSlice";
import { filterProperties } from "pages/Root/helpers";
import useNotifications from "hooks/useNotifications";

import CustomSelect from "components/CustomSelect";

UnitForm.propTypes = {
	initialValues: PropTypes.object,
	id: PropTypes.string,
};

export default function UnitForm({ initialValues, id = undefined }) {
	const defaultValues = {
		name: "",
		address: "",
		domain: "",
		members: [],
	};
	const mergedDefaults = { ...defaultValues, ...initialValues };

	const dispatch = useDispatch();

	const validationSchema = Yup.object().shape({
		name: Yup.string().min(2, "Příliš krátký název").required("Povinné pole"),
		address: Yup.string().nullable(),
		domain: Yup.string().nullable(),
	});

	const { users } = useSelector(state => state.data);
	const membersOptions = useMemo(() => {
		return Object.entries(users)?.map(([id, user]) => ({
			label: user.username,
			value: id,
		}));
	});

	// Retrieve notifications for specific category of entity using the useNotifications hook
	const notifications = useNotifications("units");

	// Define state for managing form key to trigger re-render
	const [formKey, setFormKey] = useState(0);

	// Update form key whenever initialValues change
	useEffect(() => setFormKey(prevState => prevState + 1), [initialValues]);

	return (
		<section>
			<h3>
				{id ? "Upravit" : "Vytvořit novou"} jednotku {mergedDefaults.name}
			</h3>
			{/* Render notifications reflecting updates made by this form, if available */}
			{notifications.length ? (
				<span className={cs("notification_span", notifications[0]?.type)}>
					{notifications[0]?.message}
				</span>
			) : null}
			<Formik
				key={formKey}
				initialValues={filterProperties(mergedDefaults, Object.keys(defaultValues))}
				validationSchema={validationSchema}
				onSubmit={values => {
					dispatch(
						id
							? updateObject({ entity: "units", id, values })
							: createObject({ entity: "units", values })
					);
					// Increment form key to trigger re-render
					setFormKey(prevState => prevState + 1);
				}}>
				{({ isSubmitting, handleReset }) => (
					<Form className="form">
						<label>
							Název
							<Field type="text" name="name" style={{ width: "16rem" }} />
							<ErrorMessage name="name" component="span" className="error-message" />
						</label>
						<label>
							Adresa
							<Field type="text" name="address" style={{ width: "20rem" }} />
							<ErrorMessage name="address" component="span" className="error-message" />
						</label>
						<label>
							Doména
							<Field type="text" name="domain" style={{ width: "12rem" }} />
							<ErrorMessage name="domain" component="span" className="error-message" />
						</label>
						<label>
							Členové
							<Field
								name="members"
								defaultOptions={membersOptions}
								component={CustomSelect}
								placeholder="Vyberte uživatele..."
								isMulti={true}
							/>
							<ErrorMessage name="members" component="span" className="error-message" />
						</label>
						<div></div>
						<button
							type="button"
							className="action_button reset red"
							onClick={handleReset}
							tabIndex="-1">
							Zrušit změny
						</button>
						<div></div>
						<button type="submit" className="action_button" disabled={isSubmitting}>
							{id ? "Upravit" : "Vytvořit"}
						</button>
					</Form>
				)}
			</Formik>
		</section>
	);
}
